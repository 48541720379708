/* страница/вкладка галереи */
img.video_cover_img {
  width: 1100px;
  height: 618px;
  border-radius: 20px;
}
.block_item_gallery {
  max-width: 535px;
  max-height: 340px;
  overflow: hidden;
  border-radius: 20px;
}
img.item_gallery {
  max-width: 535px;
  width: 100%;
}
/* главный слайдер */
/* изображеине доктора */
img.big-slide_doctor_img {
  width: 167px;
  height: 167px;
  border-radius: 50%;
}

/* блок с докторами на главной */
img.mp-doctors_slider_img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
}
/* блок со статьями и новостями на главной */
img.slider_articles_img {
  width: auto;
  height: 155px;
  border-radius: 20px;
  max-width: initial;
}
/* иконка Направления на странице /directions */
img.block_directions_main_img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
svg.directions_item_title_img {
  width: 38px !important;
  height: 43px !important;
}
/* Изображение ПодНаправления на странице /directions/id */
/* изображение - баннер */
img.direction_img_img {
  width: 1240px;
  /*height: 374px;*/
  border-radius: 50px;
  position: relative;
  border: 5px solid #ffffff;
}

div.direction_img_img_banner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
div.direction_img_img_banner:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 20px 40px #ffffff inset;
}

img.direction_img_img-edit {
  width: 1198px;
  max-height: 374px;
  border-radius: 50px;
}
/* изображение в поднаправлениях */
img.direction_img {
  width: 200px;
  height: 200px;
  border-radius: 20px;
}
img.direction2_img {
  width: 109px;
  height: 109px;
  border-radius: 20px;
}
/* изображени списка докторов на странице /doctors */
img.doctor_img_img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
}
/* изображени списка докторов на странице /doctors/id */
img.doctor_card_img_img {
  width: 165px;
  height: 165px;
  border-radius: 50%;
}
/* изображение-иконка на отзывах /feedbacks */
/* img.feedback_about_img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
} */
img.feedback_about_img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
/* Полная страница вопрос-ответ */
.faq_details_moderator_img_img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
}
/* страница списка Акций */
img.promo_img_img {
  width: 1120px;
  height: 370px;
  border-radius: 50px;
}
/* Награды на странице Сотрудник */
img.honor_img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

/* Новости и статьи */
img.new_details_img {
  width: 1200px;
  /*height: 375px;*/
  height: auto;
  /*max-height: 362px;*/
  border-radius: 20px;
}
/* Преимущества */
img.benefit_icon_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
img.footer_logo_img {
  width: 72px;
  height: 67px;
  max-width: initial;
  max-height: initial;
}
img.page_banner {
  width: 450px;
  border-radius: 20px;
  min-height: 50px;
}
/* стили для НЕ full-десктоп версии */
@media (max-width: 1200px) {
  img.direction_img_img {
    height: initial;
  }
  img.new_details_img {
    height: initial;
  }
  img.honor_img {
    width: 50px;
    height: auto;
  }
  img.direction_img_img {
    border-radius: 15px;
  }
}
@media (max-width: 768px) {
  img.slider_articles_img {
    border-radius: 10px;
  }
  div.direction_img_img_banner:after {
    box-shadow: inset 0px 0px 0px 0px #ffffff;
  }
  img.direction_img_img {
    border-radius: 0px;
  }
}

@media (max-width: 575.98px) {
  .big-slide._doctor {
    position: relative;
  }
  .big-slide._doctor .btn-outline-primary {
    position: absolute !important;
    z-index: 9999 !important;
    right: 8px !important;
    top: 45px !important;
  }
  /* изображение Новости и Галереи */
  img.big-slide_news_img {
    height: auto !important;
    width: 100% !important;
    -o-object-fit: initial !important;
    object-fit: initial !important;
  }
  .big-slide._doctor .big-slide__title {
    font-size: 20px !important;
  }
}
@media (max-width: 536.98px) {
  .big-slide {
    height: 63Vw !important;
  }
  .big-slide__img {
    height: 61Vw;
    overflow: hidden;
  }
  .big-slide__img img {
    border-radius: 10px;
    height: 61Vw;

  }
}
