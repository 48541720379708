/* This stylesheet generated by Transfonter (https://transfonter.org) on December 7, 2017 5:54 PM */

* {
  outline: none;
}

@font-face {
  font-family: 'TT Norms ExtraBold';
  src: url("../fonts/TTNorms-ExtraBoldItalic.eot");
  src: local("TT Norms ExtraBold Italic"), local("TTNorms-ExtraBoldItalic"), url("../fonts/TTNorms-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ExtraBoldItalic.woff") format("woff"), url("../fonts/TTNorms-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-Bold.eot");
  src: local("TT Norms Bold"), local("TTNorms-Bold"), url("../fonts/TTNorms-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Bold.woff") format("woff"), url("../fonts/TTNorms-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-Thin.eot");
  src: local("TT Norms Thin"), local("TTNorms-Thin"), url("../fonts/TTNorms-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Thin.woff") format("woff"), url("../fonts/TTNorms-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-Regular.eot");
  src: local("TT Norms Regular"), local("TTNorms-Regular"), url("../fonts/TTNorms-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Regular.woff") format("woff"), url("../fonts/TTNorms-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'TT Norms Thin';
  src: url("../fonts/TTNorms-ThinItalic.eot");
  src: local("TT Norms Thin Italic"), local("TTNorms-ThinItalic"), url("../fonts/TTNorms-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ThinItalic.woff") format("woff"), url("../fonts/TTNorms-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-Italic.eot");
  src: local("TT Norms Italic"), local("TTNorms-Italic"), url("../fonts/TTNorms-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Italic.woff") format("woff"), url("../fonts/TTNorms-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-Heavy.eot");
  src: local("TT Norms Heavy"), local("TTNorms-Heavy"), url("../fonts/TTNorms-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Heavy.woff") format("woff"), url("../fonts/TTNorms-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-Light.eot");
  src: local("TT Norms Light"), local("TTNorms-Light"), url("../fonts/TTNorms-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Light.woff") format("woff"), url("../fonts/TTNorms-Light.ttf") format("truetype");
  ;
  font-style: normal; }

@font-face {
  font-family: 'TT Norms Black';
  src: url("../fonts/TTNorms-BlackItalic.eot");
  src: local("TT Norms Black Italic"), local("TTNorms-BlackItalic"), url("../fonts/TTNorms-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-BlackItalic.woff") format("woff"), url("../fonts/TTNorms-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-ExtraLight.eot");
  src: local("TT Norms ExtraLight"), local("TTNorms-ExtraLight"), url("../fonts/TTNorms-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ExtraLight.woff") format("woff"), url("../fonts/TTNorms-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'TT Norms Light';
  src: url("../fonts/TTNorms-LightItalic.eot");
  src: local("TT Norms Light Italic"), local("TTNorms-LightItalic"), url("../fonts/TTNorms-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-LightItalic.woff") format("woff"), url("../fonts/TTNorms-LightItalic.ttf") format("truetype");
  ;
  font-style: italic; }

@font-face {
  font-family: 'TT Norms ExtraLight';
  src: url("../fonts/TTNorms-ExtraLightItalic.eot");
  src: local("TT Norms ExtraLight Italic"), local("TTNorms-ExtraLightItalic"), url("../fonts/TTNorms-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ExtraLightItalic.woff") format("woff"), url("../fonts/TTNorms-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'TT Norms Bold';
  src: url("../fonts/TTNorms-BoldItalic.eot");
  src: local("TT Norms Bold Italic"), local("TTNorms-BoldItalic"), url("../fonts/TTNorms-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-BoldItalic.woff") format("woff"), url("../fonts/TTNorms-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-Medium.eot");
  src: local("TT Norms Medium"), local("TTNorms-Medium"), url("../fonts/TTNorms-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Medium.woff") format("woff"), url("../fonts/TTNorms-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-Black.eot");
  src: local("TT Norms Black"), local("TTNorms-Black"), url("../fonts/TTNorms-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Black.woff") format("woff"), url("../fonts/TTNorms-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'TT Norms Medium';
  src: url("../fonts/TTNorms-MediumItalic.eot");
  src: local("TT Norms Medium Italic"), local("TTNorms-MediumItalic"), url("../fonts/TTNorms-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-MediumItalic.woff") format("woff"), url("../fonts/TTNorms-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'TT Norms Heavy';
  src: url("../fonts/TTNorms-HeavyItalic.eot");
  src: local("TT Norms Heavy Italic"), local("TTNorms-HeavyItalic"), url("../fonts/TTNorms-HeavyItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-HeavyItalic.woff") format("woff"), url("../fonts/TTNorms-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'TT Norms';
  src: url("../fonts/TTNorms-ExtraBold.eot");
  src: local("TT Norms ExtraBold"), local("TTNorms-ExtraBold"), url("../fonts/TTNorms-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ExtraBold.woff") format("woff"), url("../fonts/TTNorms-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'FuturaDemiC';
  src: url("../fonts/FuturaDemiC.eot");
  src: local("Futura Demi Cyrillic"), local("FuturaDemiC"), url("../fonts/FuturaDemiC.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaDemiC.woff") format("woff"), url("../fonts/FuturaDemiC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FuturaMediumC';
  src: url("../fonts/FuturaMediumC.eot");
  src: local("Futura Medium Cyrillic"), local("FuturaMediumC"), url("../fonts/FuturaMediumC.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaMediumC.woff") format("woff"), url("../fonts/FuturaMediumC.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'FuturaLightC';
  src: url("../fonts/FuturaLightC-Italic.eot");
  src: local("Futura Light Italic Cyrillic"), local("FuturaLightC-Italic"), url("../fonts/FuturaLightC-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaLightC-Italic.woff") format("woff"), url("../fonts/FuturaLightC-Italic.ttf") format("truetype");
  ;
  font-style: italic; }

@font-face {
  font-family: 'FuturaMediumC';
  src: url("../fonts/FuturaMediumC-Italic.eot");
  src: local("Futura Medium Italic Cyrillic"), local("FuturaMediumC-Italic"), url("../fonts/FuturaMediumC-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaMediumC-Italic.woff") format("woff"), url("../fonts/FuturaMediumC-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'FuturaLightC';
  src: url("../fonts/FuturaLightC.eot");
  src: local("Futura Light Cyrillic"), local("FuturaLightC"), url("../fonts/FuturaLightC.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaLightC.woff") format("woff"), url("../fonts/FuturaLightC.ttf") format("truetype");
  ;
  font-style: normal; }

@font-face {
  font-family: 'FuturaDemiC';
  src: url("../fonts/FuturaDemiC-Italic.eot");
  src: local("Futura Demi Italic Cyrillic"), local("FuturaDemiC-Italic"), url("../fonts/FuturaDemiC-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaDemiC-Italic.woff") format("woff"), url("../fonts/FuturaDemiC-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'FuturaBookC';
  src: url("../fonts/FuturaBookC-Italic.eot");
  src: local("Futura Book Italic Cyrillic"), local("FuturaBookC-Italic"), url("../fonts/FuturaBookC-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaBookC-Italic.woff") format("woff"), url("../fonts/FuturaBookC-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'FuturaBookC';
  src: url("../fonts/FuturaBookC.eot");
  src: local("Futura Book Cyrillic"), local("FuturaBookC"), url("../fonts/FuturaBookC.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaBookC.woff") format("woff"), url("../fonts/FuturaBookC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaDemiC.eot");
  src: local("Futura Demi Cyrillic"), local("FuturaDemiC"), url("../fonts/FuturaDemiC.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaDemiC.woff") format("woff"), url("../fonts/FuturaDemiC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaMediumC.eot");
  src: local("Futura Medium Cyrillic"), local("FuturaMediumC"), url("../fonts/FuturaMediumC.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaMediumC.woff") format("woff"), url("../fonts/FuturaMediumC.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaLightC-Italic.eot");
  src: local("Futura Light Italic Cyrillic"), local("FuturaLightC-Italic"), url("../fonts/FuturaLightC-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaLightC-Italic.woff") format("woff"), url("../fonts/FuturaLightC-Italic.ttf") format("truetype");
  ;
  font-style: italic; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaMediumC-Italic.eot");
  src: local("Futura Medium Italic Cyrillic"), local("FuturaMediumC-Italic"), url("../fonts/FuturaMediumC-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaMediumC-Italic.woff") format("woff"), url("../fonts/FuturaMediumC-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaLightC.eot");
  src: local("Futura Light Cyrillic"), local("FuturaLightC"), url("../fonts/FuturaLightC.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaLightC.woff") format("woff"), url("../fonts/FuturaLightC.ttf") format("truetype");
  ;
  font-style: normal; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaDemiC-Italic.eot");
  src: local("Futura Demi Italic Cyrillic"), local("FuturaDemiC-Italic"), url("../fonts/FuturaDemiC-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaDemiC-Italic.woff") format("woff"), url("../fonts/FuturaDemiC-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaBookC-Italic.eot");
  src: local("Futura Book Italic Cyrillic"), local("FuturaBookC-Italic"), url("../fonts/FuturaBookC-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaBookC-Italic.woff") format("woff"), url("../fonts/FuturaBookC-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/FuturaBookC.eot");
  src: local("Futura Book Cyrillic"), local("FuturaBookC"), url("../fonts/FuturaBookC.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaBookC.woff") format("woff"), url("../fonts/FuturaBookC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Fututra';
  src: url("../fonts/FuturaBold.ttf");
  font-weight: 700;
  font-style: normal; }


/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #F22C8B;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #0ABAB5;
  --white: #fff;
  --graphite: #55566a;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0ABAB5;
  --secondary: #F22C8B;
  --success: #28a745;
  --info: #0ABAB5;
  --warning: #ffc107;
  --danger: #F22C8B;
  --light: #f8f9fa;
  --dark: #55566a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1800px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  border-left: 5px solid #0abab5;
  background-color: #eefdfa;
  border-radius: 0 20px 20px 0;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em; 
  }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0ABAB5;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #31aaa6;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h2, .h2 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

h3, .h3 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3rem + 0.6vw) ; } }

h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  ; }

.display-1 {
  font-size: 6rem;
  ;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  ;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  ;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  ;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1300px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.container_m {
  padding-left: 0;
  padding-right: 0;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.33333%;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.66667%;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.33333%;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.66667%;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.33333%;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.66667%;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -webkit-order: 13;
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1800px) {
  .col-xxl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13; }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0; }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2; }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3; }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4; }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5; }
  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6; }
  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7; }
  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8; }
  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9; }
  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10; }
  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11; }
  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #F2F2F2; }
  .table thead th {
    padding: 0.75rem;
    vertical-align: bottom;
    border-bottom: 4px solid #F2F2F2; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d0f2f0; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a8e6e4; }

.table-hover .table-primary:hover {
  background-color: #bcecea; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bcecea; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fbcad6; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f79db3; }

.table-hover .table-secondary:hover {
  background-color: #f9b2c4; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f9b2c4; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d0f2f0; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a8e6e4; }

.table-hover .table-info:hover {
  background-color: #bcecea; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #bcecea; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbcad6; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f79db3; }

.table-hover .table-danger:hover {
  background-color: #f9b2c4; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f9b2c4; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cfd0d5; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #a7a7b2; }

.table-hover .table-dark:hover {
  background-color: #c1c3c9; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #c1c3c9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } 
    .marg_inpt {
      margin-top: -10px;
    }
    }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1799.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

th {
  color: #999999;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px; }

.field {
  position: relative;
}
  .field label {
    color: #777777;
    translate: 17px 40px;
    background-color: transparent;
    border: 2px solid transparent;
    transition: translate 250ms, background-color 250ms,  border-color 250ms;
  }
  .field:focus-within label,
  .field:not(:has(:placeholder-shown)) label {
    position: relative;
    translate: 32px 20px;
    color: #0abab5;
    border: 1px solid #0abab5;
    background-color: #fff;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    z-index: 1;    
  }

.form-control, .chosen-container-single .chosen-search input[type="text"] {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .chosen-container-single .chosen-search input[type="text"] {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .form-control::-ms-expand, .chosen-container-single .chosen-search input[type="text"]::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .chosen-container-single .chosen-search input:focus[type="text"] {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25); }
  .form-control::-webkit-input-placeholder, .chosen-container-single .chosen-search input[type="text"]::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder, .chosen-container-single .chosen-search input[type="text"]::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder, .chosen-container-single .chosen-search input[type="text"]:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder, .chosen-container-single .chosen-search input[type="text"]::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder, .chosen-container-single .chosen-search input[type="text"]::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .chosen-container-single .chosen-search input:disabled[type="text"], .form-control[readonly], .chosen-container-single .chosen-search input[readonly][type="text"] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .was-validated .chosen-container-single .chosen-search input:valid[type="text"], .chosen-container-single .chosen-search .was-validated input:valid[type="text"], .form-control.is-valid, .chosen-container-single .chosen-search input.is-valid[type="text"] {
  border-color: #28a745;
  color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .chosen-container-single .chosen-search input:valid:focus[type="text"], .chosen-container-single .chosen-search .was-validated input:valid:focus[type="text"], .form-control.is-valid:focus, .chosen-container-single .chosen-search input.is-valid:focus[type="text"] {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .chosen-container-single .chosen-search input:valid[type="text"] ~ .valid-feedback, .chosen-container-single .chosen-search .was-validated input:valid[type="text"] ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .chosen-container-single .chosen-search input:valid[type="text"] ~ .valid-tooltip,
  .chosen-container-single .chosen-search .was-validated input:valid[type="text"] ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .chosen-container-single .chosen-search input.is-valid[type="text"] ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .chosen-container-single .chosen-search input.is-valid[type="text"] ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F22C8B; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(239, 67, 108, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .was-validated .chosen-container-single .chosen-search input:invalid[type="text"], .chosen-container-single .chosen-search .was-validated input:invalid[type="text"], .form-control.is-invalid, .chosen-container-single .chosen-search input.is-invalid[type="text"] {
  border-color: #F22C8B;
  color: #F22C8B;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F22C8B' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23F22C8B' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .chosen-container-single .chosen-search input:invalid:focus[type="text"], .chosen-container-single .chosen-search .was-validated input:invalid:focus[type="text"], .form-control.is-invalid:focus, .chosen-container-single .chosen-search input.is-invalid:focus[type="text"] {
    border-color: #F22C8B;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .chosen-container-single .chosen-search input:invalid[type="text"] ~ .invalid-feedback, .chosen-container-single .chosen-search .was-validated input:invalid[type="text"] ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .chosen-container-single .chosen-search input:invalid[type="text"] ~ .invalid-tooltip,
  .chosen-container-single .chosen-search .was-validated input:invalid[type="text"] ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .chosen-container-single .chosen-search input.is-invalid[type="text"] ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .chosen-container-single .chosen-search input.is-invalid[type="text"] ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #F22C8B;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F22C8B' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23F22C8B' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #F22C8B;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F22C8B; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #F22C8B; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #F22C8B; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f37291;
  background-color: #f37291; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #F22C8B; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F22C8B; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #F22C8B;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .chosen-container-single .chosen-search input[type="text"], .chosen-container-single .chosen-search .form-inline input[type="text"] {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  min-height: 40px;
  font-weight: bold;
  border-radius: 20px;
  border-width: 3px; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25); }
  .btn.disabled, .btn:disabled {
    pointer-events: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #0ABAB5;
  border-color: #0ABAB5; }
  .btn-primary:hover {
    color: #212529;
    background-color: #3ac6c2;
    border-color: #36beb9; }
  .btn-primary:focus, .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 182, 179, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(80, 182, 179, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #999999;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #36beb9;
    border-color: #34b4af; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 182, 179, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(80, 182, 179, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #F22C8B;
  border-color: #F22C8B; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #ec2050;
    border-color: #eb1447; }
  .btn-secondary:focus, .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 95, 130, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(241, 95, 130, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #999999;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #eb1447;
    border-color: #df1344; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 95, 130, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(241, 95, 130, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #999999;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #212529;
  background-color: #0ABAB5;
  border-color: #0ABAB5; }
  .btn-info:hover {
    color: #212529;
    background-color: #3ac6c2;
    border-color: #36beb9; }
  .btn-info:focus, .btn-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 182, 179, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(80, 182, 179, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #999999;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #36beb9;
    border-color: #34b4af; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 182, 179, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(80, 182, 179, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #999999;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #F22C8B;
  border-color: #F22C8B; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ec2050;
    border-color: #eb1447; }
  .btn-danger:focus, .btn-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 95, 130, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(241, 95, 130, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #999999;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #eb1447;
    border-color: #df1344; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 95, 130, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(241, 95, 130, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #999999;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #55566a;
  border-color: #55566a; }
  .btn-dark:hover {
    color: #fff;
    background-color: #444555;
    border-color: #3e3f4e; }
  .btn-dark:focus, .btn-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 111, 128, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(111, 111, 128, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #999999;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #3e3f4e;
    border-color: #393947; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 111, 128, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(111, 111, 128, 0.5); }

.btn-outline-primary {
  color: #0ABAB5;
  border-color: #0ABAB5; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #0ABAB5;
    border-color: #0ABAB5; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #999999;
    background-color: transparent;
    border-color: #eeeeee; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #0ABAB5;
    border-color: #0ABAB5; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5); }

.btn-outline-secondary {
  color: #F22C8B;
  border-color: #F22C8B;
  cursor: pointer;
  background-color: #ffffff;
}
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #F22C8B;
    border-color: #F22C8B; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #999999;
    background-color: transparent;
    border-color: #eeeeee; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #F22C8B;
    border-color: #F22C8B; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #999999;
    background-color: transparent;
    border-color: #eeeeee; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #0ABAB5;
  border-color: #0ABAB5; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #0ABAB5;
    border-color: #0ABAB5; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #999999;
    background-color: transparent;
    border-color: #eeeeee; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #0ABAB5;
    border-color: #0ABAB5; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #999999;
    background-color: transparent;
    border-color: #eeeeee; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #F22C8B;
  border-color: #F22C8B; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #F22C8B;
    border-color: #F22C8B; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #999999;
    background-color: transparent;
    border-color: #eeeeee; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #F22C8B;
    border-color: #F22C8B; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #999999;
    background-color: transparent;
    border-color: #eeeeee; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #55566a;
  border-color: #55566a; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #55566a;
    border-color: #55566a; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 86, 106, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(85, 86, 106, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #999999;
    background-color: transparent;
    border-color: #eeeeee; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #55566a;
    border-color: #55566a; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 86, 106, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(85, 86, 106, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0ABAB5;
  text-decoration: none; }
  .btn-link:hover {
    color: #31aaa6;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 11px 30px;
  min-height: 50px;
  border-radius: 25px;
  border-width: 3px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding: 9px 30px;
  font-size: 22px;
  line-height: 26px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; 
}

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1800px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0ABAB5; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .chosen-container-single .chosen-search .input-group > input[type="text"],
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .chosen-container-single .chosen-search .input-group > input[type="text"] + .form-control, .chosen-container-single .chosen-search .input-group > .form-control + input[type="text"], .chosen-container-single .chosen-search .input-group > input[type="text"] + input[type="text"],
    .input-group > .form-control + .custom-select,
    .chosen-container-single .chosen-search .input-group > input[type="text"] + .custom-select,
    .input-group > .form-control + .custom-file,
    .chosen-container-single .chosen-search .input-group > input[type="text"] + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .chosen-container-single .chosen-search .input-group > .form-control-plaintext + input[type="text"],
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .chosen-container-single .chosen-search .input-group > .custom-select + input[type="text"],
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .chosen-container-single .chosen-search .input-group > .custom-file + input[type="text"],
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .chosen-container-single .chosen-search .input-group > input:focus[type="text"],
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .chosen-container-single .chosen-search .input-group > input:not(:last-child)[type="text"],
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .chosen-container-single .chosen-search .input-group > input:not(:first-child)[type="text"],
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .chosen-container-single .chosen-search .input-group-lg > input:not(textarea)[type="text"],
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .chosen-container-single .chosen-search .input-group-lg > input[type="text"],
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .chosen-container-single .chosen-search .input-group-sm > input:not(textarea)[type="text"],
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .chosen-container-single .chosen-search .input-group-sm > input[type="text"],
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0ABAB5;
    background-color: #0ABAB5; }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #bbebea; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #e3f7f6;
    border-color: #e3f7f6; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0ABAB5;
  background-color: #0ABAB5; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(88, 207, 203, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(88, 207, 203, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(88, 207, 203, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
    -ms-transform: translateX(0.75rem);
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(88, 207, 203, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-select:focus {
    border-color: #bbebea;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #bbebea;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(88, 207, 203, 0.25);
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(88, 207, 203, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(88, 207, 203, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(88, 207, 203, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0ABAB5;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #e3f7f6; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0ABAB5;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #e3f7f6; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0ABAB5;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #e3f7f6; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  position: relative; }
  .nav-tabs:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 2px solid #f8f8f8; }
    
  .nav-tabs .nav-tabs__add-btn {
    background: none;
    border: none;
    font-size: 16px;
    line-height: 22px;
    color: #F22C8B;
    position: relative;
    font-weight: 700;
    padding: 15px 0 17px;
    margin-left: 14px;
    outline: none;
    cursor: pointer;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s; }
    .nav-tabs .nav-tabs__add-btn:hover {
      color: #55566a; }
    .nav-tabs .nav-tabs__add-btn svg {
      margin-left: 8px;
      width: 21px;
      height: 5px; }
    .nav-tabs .nav-tabs__add-btn.js--show + .nav-tabs__add-list {
      opacity: 1;
      pointer-events: auto; }
  .nav-tabs .nav-tabs__add-list {
    right: 0;
    top: 100%;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    top: 30px;
    -webkit-transform: translate(0, 1rem);
    -ms-transform: translate(0, 1rem);
    transform: translate(0, 1rem);
    z-index: 11;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0px 50px 60px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 50px 60px rgba(0, 0, 0, 0.05);
    width: 240px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    white-space: normal; }
    .nav-tabs .nav-tabs__add-list br {
      display: none; }
    .nav-tabs .nav-tabs__add-list::before {
      content: "";
      position: absolute;
      z-index: 12;
      left: 0;
      right: 0;
      top: -8px;
      bottom: 100%; }
    .nav-tabs .nav-tabs__add-list::after {
      content: "";
      position: absolute;
      z-index: 12;
      right: 60px;
      top: -5px;
      width: 10px;
      height: 10px;
      -webkit-transform: translateX(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) rotate(45deg);
      transform: translateX(-50%) rotate(45deg);
      background-color: #fff; }
    .nav-tabs .nav-tabs__add-list .nav-item {
      margin: 0;
      padding: 11px 0; }
  .nav-tabs .nav-item {
    margin: 0 14px;
    position: relative;
    z-index: 2;
    padding: 15px 0 17px; }
    .nav-tabs .nav-item:first-child {
      margin-left: 0 !important; }
    .nav-tabs .nav-item:last-child {
      margin-right: 0 !important; }
    ._alphabet .nav-tabs .nav-item {
      margin: 0 8px;
      padding: 10px 0 12px; }
    @media (max-width: 767.98px) {
      .nav-tabs .nav-item br {
        display: none; } }
  .nav-tabs .nav-link {
    color: #55566a;
    padding: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s; }
    .nav-tabs .nav-link:before {
      content: '';
      position: absolute;
      z-index: 3;
      left: 0;
      right: 0;
      bottom: 0px;
      background-color: #F22C8B;
      height: 2px;
      opacity: 0;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s; }
    .nav-tabs .nav-link:hover {
      color: #F22C8B; }
    .nav-tabs .nav-link.disabled {
      color: #ced4da; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #F22C8B; }
    .nav-tabs .nav-link.active:before,
    .nav-tabs .nav-item.show .nav-link:before {
      opacity: 1; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.tabs-w-adds {
  position: relative;
  z-index: 3; }
  @media (min-width: 992px) {
    .tabs-w-adds .baron__track {
      display: none !important; } }
  .tabs-w-adds,
  .tabs-w-adds .baron__scroller {
    overflow: visible !important; }

.nav-pills {
  margin-left: -4px;
  margin-right: -4px; 
}
  .nav-pills .nav-link {
    color: #55566a;
    border-radius: 40px;
    height: 25px;
    line-height: 17px;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 700;
    padding: 4px 8px;
    margin: 4px; 
  }
  @media (max-width: 1199.98px) {
    .nav-pills .nav-link {
      font-size: 16px; 
    } 
  }
  .nav-pills .nav-link:not(.active) span {
    border-bottom: 1px dashed; 
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #F22C8B; 
  }
.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1799.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1800px) {
  .navbar-expand-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
      .card-group > .card {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 2rem;
  list-style: none; }
  .title-1 + nav .breadcrumb {
    margin-top: -1rem; }
  .breadcrumb a {
    color: #b2b2b2; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding: 0;
    margin: 0 8px 0 0;
    vertical-align: middle;
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 3px;
    background-color: #b2b2b2; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin: 30px 0;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  min-width: 30px;
  margin: 0 15px;
  height: 30px;
  border-radius: 5px;
  color: #55566a;
  padding: 4px 6px;
  text-align: center;
  color: #55566a;
  font-family: "TT Norms";
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: underline; }
  .page-link:hover {
    z-index: 2;
    color: #fff;
    background-color: #0ABAB5;
    text-decoration: none; }
  .page-link:focus {
    z-index: 2;
    outline: 0; }
  @media (max-width: 767.98px) {
    .page-link {
      font-size: 16px;
      margin: 0 5px; } }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #F22C8B;
  text-decoration: none; }

.page-item.disabled .page-link {
  pointer-events: none;
  text-decoration: none;
  color: #e0e0e0;
  cursor: auto; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.page-quantity {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  width: 0;
  top: 38px;
  left: 15px;
  white-space: nowrap;
  position: relative;
  color: #55566a;
  font-family: "TT Norms";
  font-size: 10px;
  font-weight: 500;
  line-height: 12px; }

.page-item.active ~ *, .page-item.active {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #0ABAB5; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #36beb9; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #F22C8B; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #eb1447; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #212529;
  background-color: #0ABAB5; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #36beb9; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(88, 207, 203, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #F22C8B; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #eb1447; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(239, 67, 108, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #55566a; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #3e3f4e; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 86, 106, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(85, 86, 106, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #2e6c6a;
  background-color: #def5f5;
  border-color: #d0f2f0; }
  .alert-primary hr {
    border-top-color: #bcecea; }
  .alert-primary .alert-link {
    color: #1f4847; }

.alert-secondary {
  color: #7c2338;
  background-color: #fcd9e2;
  border-color: #fbcad6; }
  .alert-secondary hr {
    border-top-color: #f9b2c4; }
  .alert-secondary .alert-link {
    color: #541826; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #2e6c6a;
  background-color: #def5f5;
  border-color: #d0f2f0; }
  .alert-info hr {
    border-top-color: #bcecea; }
  .alert-info .alert-link {
    color: #1f4847; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #7c2338;
  background-color: #fcd9e2;
  border-color: #fbcad6; }
  .alert-danger hr {
    border-top-color: #f9b2c4; }
  .alert-danger .alert-link {
    color: #541826; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #2c2d37;
  background-color: #dddde1;
  border-color: #cfd0d5; }
  .alert-dark hr {
    border-top-color: #c1c3c9; }
  .alert-dark .alert-link {
    color: #15161b; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0ABAB5;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none; } }

.media {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0ABAB5;
    border-color: #0ABAB5; }

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1800px) {
  .list-group-horizontal-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xxl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #2e6c6a;
  background-color: #d0f2f0; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2e6c6a;
    background-color: #bcecea; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #2e6c6a;
    border-color: #2e6c6a; }

.list-group-item-secondary {
  color: #7c2338;
  background-color: #fbcad6; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7c2338;
    background-color: #f9b2c4; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7c2338;
    border-color: #7c2338; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #2e6c6a;
  background-color: #d0f2f0; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #2e6c6a;
    background-color: #bcecea; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #2e6c6a;
    border-color: #2e6c6a; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #7c2338;
  background-color: #fbcad6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7c2338;
    background-color: #f9b2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7c2338;
    border-color: #7c2338; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #2c2d37;
  background-color: #cfd0d5; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #2c2d37;
    background-color: #c1c3c9; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #2c2d37;
    border-color: #2c2d37; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  fill: #F22C8B;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none;
    fill: #0ABAB5; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    fill: #0ABAB5; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  outline: 0;
  -webkit-box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05);
  box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background-color: #ffffff;
  padding: 50px 60px; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }

.close {
  width: 23px;
  height: 23px;
  fill: #F22C8B;
  position: absolute;
  top: 15px;
  right: 15px; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  -o-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  -o-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0ABAB5 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #36beb9 !important; }

.bg-secondary {
  background-color: #F22C8B !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #eb1447 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #0ABAB5 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #36beb9 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #F22C8B !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #eb1447 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #55566a !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #3e3f4e !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0ABAB5 !important; }

.border-secondary {
  border-color: #F22C8B !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #0ABAB5 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #F22C8B !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #55566a !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1800px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: row-reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: column-reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -webkit-flex-grow: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -webkit-flex-grow: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -webkit-justify-content: flex-start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -webkit-justify-content: flex-end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -webkit-align-items: flex-start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -webkit-align-items: flex-end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -webkit-align-items: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -webkit-align-items: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1800px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xxl-start {
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xxl-end {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xxl-center {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xxl-between {
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xxl-around {
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xxl-auto {
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xxl-start {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xxl-end {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xxl-center {
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xxl-baseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1800px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 4rem !important; }

.mt-6,
.my-6 {
  margin-top: 4rem !important; }

.mr-6,
.mx-6 {
  margin-right: 4rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 4rem !important; }

.ml-6,
.mx-6 {
  margin-left: 4rem !important; }

.m-7 {
  margin: 5rem !important; }

.mt-7,
.my-7 {
  margin-top: 5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 5rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 4rem !important; }

.pt-6,
.py-6 {
  padding-top: 4rem !important; }

.pr-6,
.px-6 {
  padding-right: 4rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 4rem !important; }

.pl-6,
.px-6 {
  padding-left: 4rem !important; }

.p-7 {
  padding: 5rem !important; }

.pt-7,
.py-7 {
  padding-top: 5rem !important; }

.pr-7,
.px-7 {
  padding-right: 5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 5rem !important; }

.pl-7,
.px-7 {
  padding-left: 5rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -4rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -4rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important; }

.m-n7 {
  margin: -5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -5rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 4rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important; }
  .m-sm-7 {
    margin: 5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 4rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important; }
  .p-sm-7 {
    padding: 5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -4rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem !important; }
  .m-sm-n7 {
    margin: -5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 4rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important; }
  .m-md-7 {
    margin: 5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 4rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important; }
  .p-md-7 {
    padding: 5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -4rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem !important; }
  .m-md-n7 {
    margin: -5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 4rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important; }
  .m-lg-7 {
    margin: 5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 4rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important; }
  .p-lg-7 {
    padding: 5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -4rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem !important; }
  .m-lg-n7 {
    margin: -5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 4rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important; }
  .m-xl-7 {
    margin: 5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 4rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important; }
  .p-xl-7 {
    padding: 5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -4rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem !important; }
  .m-xl-n7 {
    margin: -5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1800px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .m-xxl-6 {
    margin: 4rem !important; }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 4rem !important; }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 4rem !important; }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 4rem !important; }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 4rem !important; }
  .m-xxl-7 {
    margin: 5rem !important; }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 5rem !important; }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 5rem !important; }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 5rem !important; }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 5rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .p-xxl-6 {
    padding: 4rem !important; }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 4rem !important; }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 4rem !important; }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 4rem !important; }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 4rem !important; }
  .p-xxl-7 {
    padding: 5rem !important; }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 5rem !important; }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 5rem !important; }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 5rem !important; }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 5rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-n6 {
    margin: -4rem !important; }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -4rem !important; }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -4rem !important; }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -4rem !important; }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -4rem !important; }
  .m-xxl-n7 {
    margin: -5rem !important; }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -5rem !important; }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -5rem !important; }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -5rem !important; }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -5rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1800px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0ABAB5 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #31aaa6 !important; }

.text-secondary {
  color: #F22C8B !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #d41240 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #0ABAB5 !important; }

a.text-info:hover, a.text-info:focus {
  color: #31aaa6 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #F22C8B !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d41240 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  /*color: #55566a !important;*/
  color: #55566a;
}

a.text-dark:hover, a.text-dark:focus {
  color: #333440 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100% !important; }
  .chosen-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .chosen-container .chosen-drop {
    position: absolute;
    top: calc(100% - 1px);
    z-index: 1010;
    width: 100%;
    border-top: 0;
    background: #fff;
    clip: rect(0, 0, 0, 0);
    -webkit-clip-path: inset(100% 100%);
    clip-path: inset(100% 100%);
    border: 1px solid #0ABAB5;
    padding: 0;
    margin-top: -20px;
    padding-top: 20px;
    border-radius: 0 0 20px 20px; }
  .chosen-container.chosen-with-drop .chosen-single {
    z-index: 1111;
    border-radius: 20px; }
  .chosen-container.chosen-with-drop .chosen-drop {
    clip: auto;
    -webkit-clip-path: none;
    clip-path: none; }
  .chosen-container a {
    cursor: pointer; }
  .chosen-container .search-choice .group-name, .chosen-container .chosen-single .group-name {
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-weight: normal;
    color: #999999; }
  .chosen-container .search-choice .group-name:after, .chosen-container .chosen-single .group-name:after {
    content: ":";
    padding-left: 2px;
    vertical-align: top; }

.chosen-container-single .chosen-single {
  position: relative;
  display: block;
  overflow: hidden;
  background-clip: padding-box;
  text-decoration: none;
  white-space: nowrap;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #0ABAB5;
  background-color: #ffffff; }
  .chosen-container-single .chosen-single:hover {
    border-color: #0ABAB5; }
  .chosen-container-single .chosen-single:active, .chosen-container-single .chosen-single:focus {
    border-color: #0ABAB5; }

.chosen-container-single .chosen-default {
  color: #999; }

.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  margin-right: 39px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #798795;
  font-size: 15px;
  font-weight: 500;
  line-height: 36px;
  padding-left: 16px; }

.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px; }

.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  background: url("../img/chosen-sprite.png") -42px 1px no-repeat;
  font-size: 1px; }
  .chosen-container-single .chosen-single abbr:hover {
    background-position: -42px -10px; }

.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px; }

.chosen-container-single .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 39px;
  height: 100%; }
  .chosen-container-single .chosen-single div b {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../img/chosen-arrow.png") no-repeat center; }

.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap; }
  .chosen-container-single .chosen-search input[type="text"] {
    margin: 10px auto 5px;
    width: calc(100% - 24px);
    height: auto;
    outline: 0;
    border: 1px solid #aaa;
    font-size: 1em;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0;
    padding-right: 30px !important; }
  .chosen-container-single .chosen-search svg {
    width: 15px;
    height: 15px;
    fill: #0ABAB5;
    pointer-events: none;
    position: absolute;
    right: 30px;
    top: 25px; }

.chosen-container-single .chosen-drop {
  background-clip: padding-box; }

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(100% 100%);
  clip-path: inset(100% 100%); }

.chosen-container.baron-need .baron {
  height: 240px; }

.chosen-container .chosen-results {
  color: #444;
  position: relative;
  margin: 0;
  color: #505862;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  padding: 10px 0; }
  .chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 8px 15px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word; }
    .chosen-container .chosen-results li.active-result {
      display: list-item;
      cursor: pointer; }
    .chosen-container .chosen-results li.disabled-result {
      display: list-item;
      color: #ccc;
      cursor: default; }
    .chosen-container .chosen-results li.highlighted {
      color: #F22C8B; }
    .chosen-container .chosen-results li.no-results {
      color: #777;
      display: list-item;
      background: #f4f4f4; }
    .chosen-container .chosen-results li.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default; }
    .chosen-container .chosen-results li.group-option {
      padding-left: 15px; }
    .chosen-container .chosen-results li em {
      font-style: normal;
      text-decoration: underline; }

.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: auto;
  cursor: text;
  border: 1px solid #ced4da;
  height: 45px;
  color: #798795;
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  padding-left: 20px; }
  .chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none; }
    .chosen-container-multi .chosen-choices li.search-field {
      margin: 0;
      padding: 0;
      white-space: nowrap; }
      .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
        margin: 1px 0;
        padding: 0;
        height: 25px;
        outline: 0;
        border: 0 !important;
        background: transparent !important;
        color: #999;
        font-size: 100%;
        font-family: sans-serif;
        line-height: normal;
        border-radius: 0;
        width: 25px; }
    .chosen-container-multi .chosen-choices li.search-choice {
      position: relative;
      margin: 3px 5px 3px 0;
      padding: 3px 20px 3px 5px;
      border: 1px solid #aaa;
      max-width: 100%;
      border-radius: 3px;
      background-color: #eeeeee;
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
      background-image: -o-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
      background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
      background-size: 100% 19px;
      background-repeat: repeat-x;
      background-clip: padding-box;
      color: #333;
      line-height: 13px;
      cursor: default; }
      .chosen-container-multi .chosen-choices li.search-choice span {
        word-wrap: break-word; }
      .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
        position: absolute;
        top: 4px;
        right: 3px;
        display: block;
        width: 12px;
        height: 12px;
        background: url("../img/chosen-sprite.png") -42px 1px no-repeat;
        font-size: 1px; }
        .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
          background-position: -42px -10px; }
    .chosen-container-multi .chosen-choices li.search-choice-disabled {
      padding-right: 5px;
      border: 1px solid #ccc;
      background-color: #e4e4e4;
      color: #666; }
    .chosen-container-multi .chosen-choices li.search-choice-focus {
      background: #d4d4d4; }
      .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
        background-position: -42px -10px; }

.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0; }

.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  cursor: default; }

.chosen-container-active .chosen-single {
  border-color: #0ABAB5; }

.chosen-container-active.chosen-with-drop .chosen-single div {
  border-left: none;
  background: transparent; }

.chosen-container-active .chosen-choices {
  border: 1px solid #5897fb; }
  .chosen-container-active .chosen-choices li.search-field input[type="text"] {
    color: #222 !important; }

.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default; }
  .chosen-disabled .chosen-single, .chosen-disabled .chosen-choices .search-choice .search-choice-close {
    cursor: default; }

.chosen-rtl {
  text-align: right; }
  .chosen-rtl .chosen-single {
    overflow: visible;
    padding: 0 8px 0 0; }
    .chosen-rtl .chosen-single span {
      margin-right: 0;
      margin-left: 26px;
      direction: rtl; }
  .chosen-rtl .chosen-single-with-deselect span {
    margin-left: 38px; }
  .chosen-rtl .chosen-single div {
    right: auto;
    left: 3px; }
  .chosen-rtl .chosen-single abbr {
    right: auto;
    left: 26px; }
  .chosen-rtl .chosen-choices li {
    float: right; }
    .chosen-rtl .chosen-choices li.search-field input[type="text"] {
      direction: rtl; }
    .chosen-rtl .chosen-choices li.search-choice {
      margin: 3px 5px 3px 0;
      padding: 3px 5px 3px 19px; }
      .chosen-rtl .chosen-choices li.search-choice .search-choice-close {
        right: auto;
        left: 4px; }
  .chosen-rtl.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0; }
  .chosen-rtl .chosen-results li.group-option {
    padding-right: 15px;
    padding-left: 0; }
  .chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
    border: none; }
  .chosen-rtl .chosen-search input[type="text"] {
    padding: 4px 5px 4px 20px;
    background: url("../img/chosen-sprite.png") no-repeat -30px -20px;
    direction: rtl; }
  .chosen-rtl.chosen-container-single .chosen-single div b {
    background-position: 6px 2px; }
  .chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
    background-position: -12px 2px; }

.chosen-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #000;
  font-size: 14px;
  line-height: 1.4;
  border-bottom: 1px solid #eee; }
  .chosen-title .title-item {
    padding: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    font-weight: normal;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 1px;
    -ms-flex: 1 1 1px;
    flex: 1 1 1px; }
    .chosen-title .title-item + .title-item {
      border-left: 1px solid #eee; }

.baron-column._scrollbar .chosen-results.baron__scroller {
  padding-right: 0; }

[data-is_sizechoose] + .chosen-container .chosen-results li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0; }
  [data-is_sizechoose] + .chosen-container .chosen-results li .size-value {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center; }

.baron__clipper {
  position: relative;
  overflow: hidden; }

.baron__scroller {
  overflow-y: scroll;
  -ms-overflow-style: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; }
  .baron__scroller::-webkit-scrollbar {
    width: 0;
    height: 0; }

.baron__track {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0; }

.baron {
  height: 100%; }
  .baron._scrollbar > .baron__track,
  .baron._scrollbar > .baron__bar {
    display: block; }

.baron__free {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0; }

.baron__bar {
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  width: 10px;
  background: #999; }

.baron__control {
  display: none; }

.baron-column {
  position: relative; }
  .baron-column._scrollbar .baron__scroller {
    padding-right: 15px; }
  .baron-column .baron__free {
    width: 5px;
    background-color: rgba(88, 207, 203, 0.3); }
  .baron-column .baron__bar {
    border-radius: 3px;
    width: 100%;
    background-color: #0ABAB5;
    display: block; }

.baron-row {
  position: relative; }
  .baron-row._scrollbar .baron__scroller {
    padding-top: 15px; }
  .baron-row .baron__free {
    height: 5px;
    background-color: rgba(88, 207, 203, 0.3); }
  .baron-row .baron__bar {
    border-radius: 3px;
    height: 100%;
    background-color: #0ABAB5;
    display: block; }
  .baron-row .baron__track,
  .baron-row .baron__free {
    left: 0;
    bottom: auto; }

.baron._simple .baron__track {
  opacity: 0;
  position: absolute;
  z-index: 3;
  top: 10px;
  bottom: 10px;
  right: 5px;
  width: 8px;
  border-radius: 5px;
  background: #ddf;
  background: rgba(0, 0, 255, 0.1);
  pointer-events: none; }

.baron._simple._scrollbar .baron__track {
  opacity: 1; }

.baron._simple .baron__bar {
  position: absolute;
  z-index: 1;
  width: 8px;
  border-radius: 3px;
  background: #987;
  opacity: 0;
  -webkit-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
  pointer-events: auto;
  opacity: .6; }
  .baron._simple .baron__bar:hover {
    opacity: .8; }

.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-prev,
.slick-next {
  height: 22px;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
  cursor: pointer;
  line-height: 0;
  position: relative;
  z-index: 10; }
  .slick-prev::before,
  .slick-next::before {
    content: '';
    position: absolute;
    left: -15px;
    top: -15px;
    right: -15px;
    bottom: -15px;
    opacity: 0; }
    @media (max-width: 767.98px) {
      .slick-prev::before,
      .slick-next::before {
        left: -8px;
        top: -8px;
        right: -8px;
        bottom: -8px; } }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    pointer-events: none;
    opacity: 0.35; }
  @media (min-width: 992px) {
    .slick-prev,
    .slick-next {
      fill: #b2b2b2; }
      .slick-prev:hover,
      .slick-next:hover {
        fill: #F22C8B; } }
  @media (max-width: 991.98px) {
    .slick-prev,
    .slick-next {
      fill: #55566a; }
      .slick-prev:active,
      .slick-next:active {
        fill: #F22C8B; } }

.slick-prev {
  width: 29px; }

.slick-next {
  width: 89px;
  margin-left: 16px; }

.slick-dots {
  list-style-type: none;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1; }
  .slick-prev ~ .slick-dots,
  .slick-next ~ .slick-dots {
    margin-right: 40px; }
  .slick-dots li {
    font-size: 0;
    line-height: 0; }
    .slick-dots li.slick-active button {
      background-color: #F22C8B; }
    .slick-dots li:not(:last-child) {
      margin-right: 20px; }
    .slick-dots li button {
      position: relative;
      outline: none;
      border-radius: 50%;
      border: none;
      padding: 0;
      width: 10px;
      height: 10px;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      background-color: #0ABAB5; }
      .slick-dots li button:hover {
        background-color: #F22C8B; }
      .slick-dots li button::before {
        content: "";
        position: absolute;
        top: -8px;
        left: -8px;
        right: -8px;
        bottom: -8px;
        border-radius: 50%; }
  @media (max-width: 767.98px) {
    .slick-dots li:not(:last-child) {
      margin-right: 11px; }
    .slick-dots li button {
      width: 7px;
      height: 7px; } }

.slick-slider-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 15px 0;
}
  @media (min-width: 768px) and (max-width: 991.98px) {
    .slick-slider-nav {
      margin: 30px; }
      .slick-slider-nav .slick-arrow {
        display: none; } }
  @media (max-width: 767.98px) {
    .slick-slider-nav {
      margin: 15px 0; } }

.slick-slide {
  outline: none; }

.title-0,
h1,
.title-1,
h2,
.title-2,
h3,
.title-3,
h4,
.title-4 {
  /*font-family: Futura; */
  font-family: Futura;
}

.title-0 {
  font-size: 130px;
  font-weight: 700;
  line-height: 134px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .title-0 {
      font-size: 104px;
      line-height: 107.2px; } }
  @media (max-width: 767.98px) {
    .title-0 {
      font-size: 60px;
      line-height: 70px; } }

h1,
.title-1 {
  font-size: 54px;
  /*font-weight: 700;*/
  font-weight: 400; /* добавлено */
  line-height: 57px;
  margin-bottom: 44px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    h1,
    .title-1 {
      font-size: 43.2px;
      line-height: 45.6px; } }
  @media (max-width: 991.98px) {
    h1,
    .title-1 {
      margin-bottom: 32px; } }
  @media (max-width: 767.98px) {
    h1,
    .title-1 {
      font-size: 28px;
      line-height: 27px;
      margin-bottom: 22px; } }

h2,
.title-2 {
  font-size: 40px;
  /*font-weight: 700;*/
  font-weight: 400;
  line-height: 43px;
  margin-bottom: 22px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    h2,
    .title-2 {
      font-size: 32px;
      line-height: 34.4px; } }
  @media (max-width: 767.98px) {
    h2,
    .title-2 {
      font-size: 28px;
      line-height: 27px;
      margin-bottom: 16px; } }

h3,
.title-3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 22px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    h3,
    .title-3 {
      font-size: 24px;
      line-height: 24px; } }
  @media (max-width: 767.98px) {
    h3,
    .title-3 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 16px; } }

h4,
.title-4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 11px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    h4,
    .title-4 {
      font-size: 17.6px;
      line-height: 19.2px; } }

p,
.paragraph
{
  font-weight: 500;
  /*font-weight: 400; !* добавлен *!*/
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  -webkit-margin-before: 1em;
  margin-block-start: 1em;
  -webkit-margin-after: 1em;
  margin-block-end: 1em;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px; }
  p.big,
  p .big,
  .paragraph.big,
  .paragraph .big {
    font-weight: 400; /* добавлен */
    font-size: 22px;
    line-height: 29px; }
    @media (max-width: 767.98px) {
      p.big,
      p .big,
      .paragraph.big,
      .paragraph .big {
        font-size: 20px;
        line-height: 24px; } }

/* правка таблицы */
.paragraph table {
  /*width: 100% !important;*/
  overflow: auto;
  display: block;
}
/* скругления картинок после CKEditor */
/*.paragraph p img,*/
/*.paragraph img*/
/*{*/
/*  border-radius: 20px;*/
/*}*/


.link-icon,
.link {
  color: #55566a;
  fill: #55566a;
  text-decoration: underline;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s; }
  .link-icon:hover,
  .link:hover {
    color: #F22C8B;
    fill: #F22C8B;
    text-decoration: none; }

.link-icon-red,
.link-red {
  color: #F22C8B;
  fill: #F22C8B;
  text-decoration: underline;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s; }
  .link-icon-red:hover,
  .link-red:hover {
    color: #0ABAB5;
    fill: #0ABAB5;
    text-decoration: none; }

.link-icon,
.link-icon-red {
  font-size: 0;
  line-height: 0; }

[data-move-to] {
  text-decoration: none !important;
  border-bottom: 2px dashed #0ABAB5;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s; }
  [data-move-to]:hover {
    border-bottom-color: transparent; }

hr {
  border-color: #f2f2f2; }

dl {
  display: block;
  margin-bottom: 0; }
  dl + dl {
    padding-top: 11px;
    margin-top: 11px;
    border-top: 1px solid #F8F8F8; }
  dl dt {
    color: #B2B2B2;
    font-weight: normal; }
  dl dd {
    color: #55566a;
    margin-bottom: 0; }
    dl dd + dd {
      margin-top: 11px; }
  @media (min-width: 768px) {
    dl:after {
      content: '';
      clear: both;
      display: table; }
    dl dt {
      width: 35%;
      float: left;
      padding-right: 30px; }
    dl dd {
      width: 65%;
      float: right;
      clear: right; } }
  @media (max-width: 767.98px) {
    dl + dl {
      padding-top: 12px;
      margin-top: 18px; }
    dl dd {
      margin-top: 6px; }
      dl dd + dd {
        margin-top: 12px; } }

ul.ui-ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0; }
  ul.ui-ul li {
    position: relative;
    padding-left: 30px;
    margin: 22px 0; }
    ul.ui-ul li:before {
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #b2b2b2; }
  @media (max-width: 767.98px) {
    ul.ui-ul li {
      padding-left: 15px; } }

.btn-primary {
  color: #fff; }
  .btn-primary:hover {
    background-color: #6ce3df;
    border-color: #6ce3df;
    color: #fff; 
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #44bbb7;
    border-color: #44bbb7;
    color: #fff; }

.btn-secondary:hover {
  background-color: #ffa0e2;
  border-color: #ffa0e2; 
  color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #82112c;
  border-color: #82112c; }

.btn-outline-secondary:hover {
  border-color: #ffa0e2;
  color: #ffa0e2;
  /*background-color: transparent; */
  background-color: #ffffff;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  border-color: #82112c;
  color: #F22C8B;
  background-color: transparent; }

.btn-light {
  color: #55566a; }
  .btn-light:hover {
    background-color: #F22C8B;
    border-color: #F22C8B;
    color: #fff; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    background-color: #0ABAB5;
    border-color: #0ABAB5;
    color: #fff; }

.form-check {
  padding-left: 0; }
  .form-check-label {
    padding-left: 32px;
    cursor: pointer;
    position: relative;
    color: #5c3b57;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; }
    .form-check-label::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 23px;
      height: 23px;
      border: 2px solid #0ABAB5;
      background-color: #ffffff; }
    .form-check-label::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      width: 13px;
      height: 13px;
      -webkit-box-shadow: 0 0 60px rgba(86, 212, 191, 0.2);
      box-shadow: 0 0 60px rgba(86, 212, 191, 0.2);
      background-color: #0ABAB5;
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      opacity: 0.5;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s; }
    input[disabled] ~ .form-check-label::before {
      border: 2px solid #eeeeee;
      background-color: transparent; }
    input:not([disabled]) ~ .form-check-label:hover::after {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }
    input:checked ~ .form-check-label::after {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      opacity: 1; }
    input[type=checkbox] ~ .form-check-label::before {
      border-radius: 5px; }
    input[type=checkbox] ~ .form-check-label::after {
      border-radius: 3px; }
    input[type=radio] ~ .form-check-label::before {
      border-radius: 50%; }
    input[type=radio] ~ .form-check-label::after {
      border-radius: 50%; }

.form-control, .chosen-container-single .chosen-search input[type="text"] {
  height: 40px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  border-color: #0ABAB5;
  border-width: 2px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px; }
  .form-control:focus, .chosen-container-single .chosen-search input:focus[type="text"] {
    -webkit-box-shadow: 0 0 60px rgba(88, 207, 203, 0.2);
    box-shadow: 0 0 60px rgba(88, 207, 203, 0.2); }
  .form-control:disabled, .chosen-container-single .chosen-search input:disabled[type="text"], .form-control.disabled, .chosen-container-single .chosen-search input.disabled[type="text"] {
    border-color: #eeeeee;
    color: #999;
    background-color: transparent; }

label {
  padding-left: 17px; }

.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .chosen-container-single .chosen-search input:invalid[type="text"] ~ .invalid-feedback, .chosen-container-single .chosen-search .was-validated input:invalid[type="text"] ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.was-validated .chosen-container-single .chosen-search input:invalid[type="text"] ~ .invalid-tooltip,
.chosen-container-single .chosen-search .was-validated input:invalid[type="text"] ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .chosen-container-single .chosen-search input.is-invalid[type="text"] ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.chosen-container-single .chosen-search input.is-invalid[type="text"] ~ .invalid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .chosen-container-single .chosen-search input:valid[type="text"] ~ .valid-feedback, .chosen-container-single .chosen-search .was-validated input:valid[type="text"] ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.was-validated .chosen-container-single .chosen-search input:valid[type="text"] ~ .valid-tooltip,
.chosen-container-single .chosen-search .was-validated input:valid[type="text"] ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .chosen-container-single .chosen-search input.is-valid[type="text"] ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.chosen-container-single .chosen-search input.is-valid[type="text"] ~ .valid-tooltip {
  padding-left: 17px; }

body,
html {
  padding: 0;
  margin: 0;
  font-size: 16px;
  min-height: 100%; }
  body *,
  html * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }

svg,
img {
  max-width: 100%;
  max-height: 100%; }

body {
  position: relative;
  min-height: 100vh;
  color: #55566a;
  font-family: "TT Norms";
  font-size: 16px;
  font-weight: 500;
  line-height: 22px; }

@media (max-width: 1199.98px) {
  main {
    /*padding-top: 100px;*/
    padding-top: 125px; /* добавлено */
  }
}
@media (max-width: 767.98px)  {
  main {
    padding-top: 100px; /* добавлено */
  }
  .tac {
    text-align: center;
  }
}

.nav-line {
  border-bottom: 2px solid #f8f8f8; }
  .nav-line .nav-item {
    max-width: 160px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
    .nav-line .nav-item:not(:last-child) {
      margin-right: 25px; }
  .nav-line .nav-link {
    margin-bottom: -2px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    color: #55566a;
    padding: 8px 0;
    border-bottom: 2px solid transparent; }
    .nav-line .nav-link:hover {
      border-color: #55566a; }
    .nav-line .nav-link.active {
      color: #F22C8B;
      border-bottom-color: #F22C8B; }

.mobile-back {
  margin: 15px 0; }
  @media (min-width: 768px) {
    .mobile-back {
      display: none; } }
  .mobile-back a {
    text-decoration: none;
    color: #F22C8B;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 700;
    line-height: 23px; }
    .mobile-back a svg {
      width: 17px;
      height: 15px;
      margin-top: 4px;
      margin-right: 12px;
      fill: #F22C8B; }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .col-lg-20p {
    max-width: 20%;
    -webkit-flex-basis: 20%;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%; } }

.header {
  background-color: #fff;
  padding: 40px 0;
}
  .header .container {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  @media (min-width: 1200px) and (max-width: 1879px) {
    .header-top.container {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      /*height: 60px;*/
      padding-left: 258px;
      /*margin: -7.5px auto 15px;*/
      /*height: 80px; !* Шараванский Е.Е. убрал 23.09.2022 ввиду необходимости выводить четвертый номер в заголовке *!*/
      margin: -7.5px auto 0px; /* изменено */
    }

      .header-top.container > * {
        margin: 7.5px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 250px;
        -ms-flex: 0 0 250px;
        flex: 0 0 250px; } }
  @media (min-width: 1200px) and (max-width: 1299px) {
    .header-top.container {
      padding-left: 300px; } 
  }
  .header-city {
    position: absolute;
    right: calc(100% + 90px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 220px;
    -ms-flex: 0 0 220px;
    flex: 0 0 220px;
    width: 220px;
    top: 36px; }
    .header-city span {
      color: #F22C8B;
      line-height: 0.8;
      display: block;
      margin: 0 6px; }
    .header-city a {
      color: #55566a;
      line-height: 0.8;
      display: block;
      margin: 0 6px;
      text-decoration: none !important;
      border-bottom: 1px dashed; }
    @media (min-width: 1200px) {
      .header-city {
        position: static;
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
        margin-bottom: 5px; }
        .header-city span {
          margin: 0 6px 0 0; } }
  .header-logo {
    height: 60px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
    @media (min-width: 1200px) and (max-width: 1299px) {
      .header-logo {
        left: 0; } }
  .header-phone {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 145px;
   }
    .header-phone__link {
      color: #55566a;
      font-weight: bold;
      text-decoration: none !important; }
      .header-phone__link span:last-child {
        text-decoration: underline; }
    .header-phone__text {
      color: #7d7e92;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px; }
  .header-social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: calc(100% + 70px);
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75px; }
    .header-social a {
      width: 18px;
      height: 18px;
      display: block; }
      .header-social a:not(:last-child) {
        margin-right: 10px; }
    @media (min-width: 1200px) {
      .header-social {
        position: static;
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6; } }
    @media (max-width: 1199px) {
      .header-social {
        display: none; } }
  .header-special {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: calc(100% + 200px);
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 94px;
    top: 33px; }
    .header-special .link-icon-red {
      width: 22px;
      height: 18px;
      display: block; }
    .header-special a:not(:last-child) {
      margin-right: 10px; }
    @media (min-width: 1200px) {
      .header-special {
        position: static;
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6; } }
    @media (max-width: 1199px) {
      .header-special {
        display: none; } }
  .header-add {
    display: none; }
  .header-bottom.container {
    margin-top: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .header-menu-btn {
    width: 22px;
    height: 22px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 22px;
    -ms-flex: 0 0 22px;
    flex: 0 0 22px;
    margin-right: 12px;
    line-height: 0;
    cursor: pointer;
    fill: #0ABAB5;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s; }
    .header-menu-btn:hover {
      fill: #F22C8B; }
    @media (min-width: 1300px) {
      .header-menu-btn {
        margin-left: -49px; } }
    @media (max-width: 1199.98px) {
      .header-menu-btn {
        width: 17px;
        height: 17px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 17px;
        -ms-flex: 0 0 17px;
        flex: 0 0 17px; } }
  .header-search-btn {
    width: 17px;
    height: 17px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 17px;
    -ms-flex: 0 0 17px;
    flex: 0 0 17px;
    fill: #0ABAB5;
    line-height: 0; }
  .header-search {
    width: 240px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 240px;
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    position: relative;
    height: 40px;
    height: 64px; }
    .header-search .form-control, .header-search .chosen-container-single .chosen-search input[type="text"], .chosen-container-single .chosen-search .header-search input[type="text"] {
      position: absolute;
      width: 240px;
      right: 0;
      top: 12px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      @media (min-width: 1200px) and (max-width: 1251px) {
        .header-search {
      -webkit-flex: 0 0 200px;
      -ms-flex: 0 0 200px;
      flex: 0 0 200px;
        }
        }
      @media (min-width: 1200px) {
        .js--active-results .header-search .form-control, .js--active-results .header-search .chosen-container-single .chosen-search input[type="text"], .chosen-container-single .chosen-search .js--active-results .header-search input[type="text"], .header-search .form-control:focus, .header-search .chosen-container-single .chosen-search input:focus[type="text"], .chosen-container-single .chosen-search .header-search input:focus[type="text"] {
          width: 860px; } }
      @media (max-width: 1299px) {
        .js--active-results .header-search .form-control, .js--active-results .header-search .chosen-container-single .chosen-search input[type="text"], .chosen-container-single .chosen-search .js--active-results .header-search input[type="text"], .header-search .form-control:focus, .header-search .chosen-container-single .chosen-search input:focus[type="text"], .chosen-container-single .chosen-search .header-search input:focus[type="text"] {
          width: 820px; } }
    .header-search button {
      outline: none; }
    .header-search ._search {
      height: 40px;
      width: 40px;
      padding-bottom: 3px;
      position: absolute;
      z-index: 2;
      top: 12px;
      right: 0;
      border: none;
      background: none;
      opacity: 1;
      pointer-events: auto;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .js--active-results .header-search ._search {
        pointer-events: none;
        opacity: 0; }
      .header-search ._search svg {
        fill: #0ABAB5;
        width: 15px;
        height: 15px; }
    .header-search ._clear {
      height: 40px;
      width: 40px;
      padding-bottom: 3px;
      position: absolute;
      z-index: 2;
      top: 12px;
      right: 0;
      border: none;
      background: none;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .js--active-results .header-search ._clear {
        opacity: 1;
        pointer-events: auto; }
      .header-search ._clear svg {
        fill: #F22C8B;
        width: 15px;
        height: 15px; }
  .header-order {
    width: 200px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 200px;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
    margin-left: 10px; }
  @media (max-width: 1199.98px) {
    .header {
      padding: 20px 0;
      position: fixed;
      left: 0;
      right: 0;
      top: 0px;
      /*top: 30px; !* добавлено *!*/
      z-index: 950; }
      .header-phone, .header-city, .header-bottom {
        display: none !important; }
      .header-add {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3; }
      .header-menu-btn, .header-search-btn {
        margin-left: 30px;
        margin-right: 0; }
      .header-social, .header-special {
        display: none;
        position: static;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; }
        .header-social a:not(:last-child), .header-special a:not(:last-child) {
          margin: 0 10px 0 0; } }
  @media (max-width: 991.98px) {
    .header-social, .header-special {
      display: none; } }
  @media (max-width: 767.98px) {
    .header {
      position: absolute;
      padding: 15px 0; }
      .header .container {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start; }
      .header-order, .header-search-btn {
        display: none; } }

.header-fix {
  -webkit-box-shadow: 0 0 60px rgba(86, 212, 191, 0.2);
  box-shadow: 0 0 60px rgba(86, 212, 191, 0.2);
  background-color: #ffffff;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .header-fix.js--show {
    opacity: 1;
    pointer-events: auto; }
  .header-fix .container {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 100px;
    height: 88px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .header-fix .header-logo {
    margin-left: 0; }
  .header-fix .header-order {
    position: absolute;
    top: 23px;
    right: -130px;
    margin-top: 5px;
    margin-left: 0;
  }
  @media (max-width: 1199.98px) {
    .header-fix {
      display: none !important; } }
  @media screen and (max-width: 1300px) {
    .header-fix .header-logo {
      left: 60px; }
    .header-fix .header-menu-btn {
      left: 15px; }
     }
  @media screen and (max-width: 1200px) {
    .header-fix {
      opacity: 0; }
      .header-fix .header-city,
      .header-fix .header-special,
      .header-fix .header-search-btn {
        display: none; }
      .header-fix .container {
        padding-right: 15px;
        padding-left: 280px;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between; }
      .header-fix .header-logo {
        position: absolute;
        left: 15px;
        top: 14px; }
      .header-fix .header-phone {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 200px;
        -ms-flex: 1 1 200px;
        flex: 1 1 200px;
        margin-bottom: 4px;
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start;
        padding-top: 7px;
        padding-right: 30px;

      }
        .header-fix .header-phone__link {
          white-space: nowrap; }
          .header-fix .header-phone__link + .d-none {
            display: none !important; }
      .header-fix .header-menu-btn {
        position: absolute;
        left: -33px;
        top: 35px;
        margin: 0; }
      .header-fix .header-order {
        position: static;
        margin: 0; } }

.js--open-menu {
  overflow: hidden; }
  .js--open-menu .mainmenu {
    opacity: 0.5;
    opacity: 1;
    pointer-events: auto; }

.mainmenu {
  position: fixed;
  z-index: 1111;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  background-color: #fff;
  overflow: auto;
  pointer-events: none;
  opacity: 0; }
  .mainmenu-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 45px; }
  .mainmenu-close-btn {
    width: 18px;
    height: 17px;
    fill: #0ABAB5;
    line-height: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    cursor: pointer; }
    .mainmenu-close-btn:hover {
      fill: #F22C8B; }
  .mainmenu-search {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    position: relative; }
    .mainmenu-search button {
      outline: none; }
    .mainmenu-search ._search {
      height: 40px;
      width: 40px;
      padding-bottom: 3px;
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      border: none;
      background: none;
      opacity: 1;
      pointer-events: auto;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .js--active-results .mainmenu-search ._search {
        pointer-events: none;
        opacity: 0; }
      .mainmenu-search ._search svg {
        fill: #0ABAB5;
        width: 15px;
        height: 15px; }
    .mainmenu-search ._clear {
      height: 40px;
      width: 40px;
      padding-bottom: 3px;
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      border: none;
      background: none;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .js--active-results .mainmenu-search ._clear {
        opacity: 1;
        pointer-events: auto; }
      .mainmenu-search ._clear svg {
        fill: #F22C8B;
        width: 15px;
        height: 15px; }
  .mainmenu-order {
    width: 200px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 200px;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
    margin-left: 30px; }
  .mainmenu-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
    .mainmenu-col a {
      color: #7d7e92;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      display: inline-block;
      margin-bottom: 10px; }
      .mainmenu-col a .big {
        display: inline-block;
        color: #55566a;
        font-size: 22px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 15px; }
  .mainmenu .mainmenu-phone {
    margin-bottom: 10px; }
    .mainmenu .mainmenu-phone__link {
      font-size: 14px;
      font-weight: 700;
      line-height: 14px; }
    .mainmenu .mainmenu-phone__text {
      color: #7d7e92;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px; }
  .mainmenu-social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .mainmenu-social a {
      width: 26px;
      height: 26px;
      display: block; }
      .mainmenu-social a:not(:last-child) {
        margin-right: 15px; }
  @media (min-width: 1200px) {
    .mainmenu {
      padding-top: 134px;
      padding-bottom: 60px; }
      .mainmenu .container {
        padding-left: 65px;
        position: relative; }
      .mainmenu-close-btn {
        position: absolute;
        left: 18px;
        top: 10px; } }
  @media screen and (min-width: 1200px) and (min-width: 1300px) {
    .mainmenu .container {
      padding-left: 15px; }
    .mainmenu-close-btn {
      left: -33px; } }
  @media (max-width: 1199.98px) {
    .mainmenu {
      padding: 30px 0; }
      .mainmenu-close-btn {
        margin-left: 30px; } }
  @media (max-width: 991.98px) {
    .mainmenu {
      padding: 30px 0; }
      .mainmenu-order {
        display: none; }
      .mainmenu-close-btn {
        position: static;
        position: relative;
        top: -2px;
        left: auto;
        right: 0;
        margin-left: 30px; } }
  @media (max-width: 767.98px) {
    .mainmenu {
      display: none; } }

.js--open-menu {
  overflow: hidden; }
  .js--open-menu .mobilemenu {
    opacity: 0.5;
    opacity: 1;
    pointer-events: auto; }

.mobilemenu {
  position: fixed;
  z-index: 1111;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  background-color: #fff;
  overflow: auto;
  pointer-events: none;
  opacity: 0;
  padding-top: 15px;
  -webkit-transition: opacity 0.15s;
  -o-transition: opacity 0.15s;
  transition: opacity 0.15s; }
  @media (min-width: 768px) {
    .mobilemenu {
      display: none; } }
  .mobilemenu-logo {
    margin-bottom: 30px;
    width: 232px;
    height: 60px; }
  .mobilemenu-close-btn {
    width: 18px;
    height: 17px;
    fill: #0ABAB5;
    line-height: 0;
    position: absolute;
    top: 15px;
    right: 15px; }
  .mobilemenu-search {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    position: relative;
    margin: 15px 0 30px; }
    .mobilemenu-search button {
      outline: none; }
    .mobilemenu-search ._search {
      height: 40px;
      width: 40px;
      padding-bottom: 3px;
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      border: none;
      background: none;
      opacity: 1;
      pointer-events: auto;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .js--active-results .mobilemenu-search ._search {
        pointer-events: none;
        opacity: 0; }
      .mobilemenu-search ._search svg {
        fill: #0ABAB5;
        width: 15px;
        height: 15px; }
    .mobilemenu-search ._clear {
      height: 40px;
      width: 40px;
      padding-bottom: 3px;
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      border: none;
      background: none;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .js--active-results .mobilemenu-search ._clear {
        opacity: 1;
        pointer-events: auto; }
      .mobilemenu-search ._clear svg {
        fill: #F22C8B;
        width: 15px;
        height: 15px; }
  .mobilemenu-city {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 10px 0 10px; }
    .mobilemenu-city span {
      color: #F22C8B;
      line-height: 0.8;
      display: block;
      margin: 0 6px; }
    .mobilemenu-city a {
      color: #55566a;
      line-height: 0.8;
      display: block;
      margin: 0 6px;
      text-decoration: none !important;
      border-bottom: 1px dashed; }
  .mobilemenu-nav {
    margin: 15px 0; }
    .mobilemenu-nav > ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .mobilemenu-nav > ul > li + li {
        border-top: 2px solid #f8f8f8; }
      .mobilemenu-nav > ul > li > a {
        min-height: 47px;
        color: #55566a;
        font-family: "TT Norms";
        font-size: 20px;
        font-weight: 700;
        line-height: 39px;
        padding-right: 15px;
        position: relative;
        width: 100%;
        display: block;
        text-decoration: none !important; }
        .mobilemenu-nav > ul > li > a:not(.js--mobilemenu-open) svg {
          display: none; }
        .mobilemenu-nav > ul > li > a svg {
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translateY(calc( -50% + 2px)) rotate(-90deg) translateY(15px);
          -ms-transform: translateY(calc( -50% + 2px)) rotate(-90deg) translateY(15px);
          transform: translateY(calc( -50% + 2px)) rotate(-90deg) translateY(15px);
          width: 15px;
          height: 8px;
          -webkit-transform-origin: 0 100%;
          -ms-transform-origin: 0 100%;
          transform-origin: 0 100%;
          fill: #eee; }
  .mobilemenu-nav__inner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background-color: #fff;
    z-index: 1112;
    padding: 15px 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    pointer-events: none;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%); }
    .mobilemenu-nav__inner.js--inner-opened {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
      pointer-events: auto; }
    .mobilemenu-nav__inner-back {
      color: #55566a;
      font-family: "TT Norms";
      font-size: 22px;
      font-weight: 700;
      line-height: 39px;
      height: 47px;
      border-bottom: 2px solid #f8f8f8; }
    .mobilemenu-nav__inner ul {
      list-style-type: none;
      margin: 15px 0;
      padding: 0; }
      .mobilemenu-nav__inner ul li {
        margin: 15px 0; }
        .mobilemenu-nav__inner ul li a {
          color: #7d7e92;
          font-family: "TT Norms";
          font-size: 16px;
          font-weight: 500;
          line-height: 18px; }
        .mobilemenu-nav__inner ul li ul {
          padding-left: 15px; }
  .mobilemenu-phone {
    margin-bottom: 15px; }
    .mobilemenu-phone__link {
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      color: #55566a; }
    .mobilemenu-phone__text {
      color: #7d7e92;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px; }
  .mobilemenu-social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 30px 0; }
    .mobilemenu-social a {
      width: 35px;
      height: 35px;
      display: block; }
      .mobilemenu-social a:not(:last-child) {
        margin-right: 15px; }

.footer hr {
  margin: 30px 0;
  border-top: 2px solid rgba(238, 238, 238, 0.3); }

.footer-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .footer-logo__text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 20px; }
    .footer-logo__text small {
      display: block;
      color: #55566a;
      font-family: "TT Norms";
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.12px;
      line-height: 12px; }
    .footer-logo__text .big {
      display: block;
      color: #55566a;
      font-family: Futura;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.32px;
      line-height: 21px; }

.footer-rights {
  color: #b2b2b2;
  font-family: "TT Norms";
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  margin: 30px 0; }

.footer-nav [class*='col'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.footer-nav a {
  color: #55566a;
  font-family: "TT Norms";
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 10px; }

.footer-nav2 [class*='col'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .footer-nav2 [class*='col'] a {
    color: #7d7e92;
    display: inline-block;
    margin-bottom: 10px;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-decoration: underline; }
    .footer-nav2 [class*='col'] a .big {
      color: #55566a;
      font-family: Futura;
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 20px;
      display: inline-block; }

.footer-phone {
  display: none; }

.footer-social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 20px 0; }
  .footer-social a {
    width: 26px;
    height: 26px;
    display: block; }
    .footer-social a:not(:last-child) {
      margin-right: 10px; }

@media (max-width: 767.98px) {
  .footer hr {
    margin: 20px 0; }
  .footer-nav, .footer-nav2 {
    display: none; }
    .footer-nav + hr, .footer-nav2 + hr {
      display: none; }
  .footer-address [class*='col'] {
    margin-top: 20px;
    text-align: center; }
  .footer-phone {
    display: block;
    text-align: center; }
    .footer-phone a {
      color: #55566a;
      font-family: "TT Norms";
      font-size: 20px;
      font-weight: 700;
      line-height: 22px; } }

.subscribe {
  margin-top: 120px;
  padding: 64px 0 26px;
  position: relative;
  z-index: 2;
  text-align: center; }
  .subscribe__bg {
    position: absolute;
    height: 100%;
    top: 0;
    left: -100px;
    right: -100px;
    z-index: -1;
    fill: #0ABAB5; }
    .subscribe__bg svg {
      width: 100%;
      height: 100%;
      max-height: none; }
  .subscribe__title {
    color: #ffffff;
    font-family: Futura;
    font-size: 40px;
    font-weight: 700;
    line-height: 43px; }
  .subscribe__subtitle {
    margin-top: 15px;
    color: #ffffff;
    font-family: "TT Norms";
    font-size: 22px;
    font-weight: 700;
    line-height: 24px; }
  .subscribe__form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 30px auto;
    width: 600px;
    max-width: 100%; }
    .subscribe__form input {
      border: none;
      padding-left: 20px;
      margin-right: 20px;
      -webkit-box-shadow: none;
      box-shadow: none; }
  .subscribe__links {
    margin-top: 30px;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .subscribe__links a {
      color: #ffffff;
      font-family: "TT Norms";
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      text-decoration: underline; }
  @media (max-width: 991.98px) {
    .subscribe__bg {
      left: -350px;
      right: -350px; } }
  @media (max-width: 767.98px) {
    .subscribe {
      padding-top: 16px;
      margin-top: 45px; }
      .subscribe__title {
        font-size: 22px;
        line-height: 22px; }
      .subscribe__subtitle {
        font-size: 16px;
        line-height: 22px;
        margin-top: 5px; }
      .subscribe__bg {
        left: -500px;
        right: -500px; }
      .subscribe__form {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 15px 0; }
        .subscribe__form input {
          margin-right: 0;
          margin-bottom: 15px; }
        .subscribe__form .btn {
          width: 100%; }
      .subscribe__links {
        margin-top: 15px; } }

/*.big-slider .slick-list {
  overflow: visible !important; }*/
  @media (min-width: 768px) and (max-width: 991.98px) {
    .big-slider .slick-list::before {
      position: absolute;
      content: '';
      z-index: 3;
      top: 0;
      bottom: 0;
      right: calc( 50% - 50vw);
      background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0)));
      background-image: -o-linear-gradient(right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      background-image: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      width: 290px; } }

.big-slide {
  height: 370px;
  margin-right: 20px;
  outline: none; }
  .big-slide._news {
    width: 550px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 40px 60px;
    position: relative;
    overflow: hidden;
    border-radius: 10px; }
    .big-slide._news .big-slide__title {
      color: #fff;
      font-family: Futura;
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      text-decoration: underline;
      z-index: 2;
      position: relative;
      display: inline-block;
      margin-top: 5px; }
      .big-slide._news .big-slide__title::before {
        content: "";
        position: absolute;
        z-index: 3;
        left: -300px;
        right: -300px;
        top: -300px;
        bottom: -300px; }
    .big-slide._news .big-slide__text,
    .big-slide._news .big-slide__text p {
      margin-top: 10px;
      color: #fff;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      z-index: 2;
      position: relative; }
    .big-slide._news .big-slide__img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1; }
      .big-slide._news .big-slide__img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: 50% 50%;
        object-position: 50% 50%; }
    .big-slide._news .big-slide__img-blur {
      position: absolute;
      top: 215px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      overflow: hidden;
      -webkit-filter: blur(25px);
      filter: blur(25px); }
      .big-slide._news .big-slide__img-blur img {
        width: 100%;
        max-height: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0; }
  .big-slide._doctor {
    width: 300px;
    padding: 0 30px 30px;
    -webkit-box-shadow: 0 50px 60px rgba(218, 218, 218, 0.2);
    box-shadow: 0 50px 60px rgba(218, 218, 218, 0.2);
    border-radius: 10px;
    border: 1px solid #c6eee7;
    background-color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .big-slide._doctor .big-slide__doctor {
      overflow: hidden;
      background-color: #c4c4c4;
      z-index: 2;
      position: relative;
      border-radius: 50%;
      margin-top: -35px;
      width: 167px;
      height: 167px;
      margin-bottom: 20px;
      border: 4px solid #fff;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 167px;
      -ms-flex: 0 0 167px;
      flex: 0 0 167px; }
    .big-slide._doctor .big-slide__title {
      color: #55566a;
      margin-bottom: 8px;
      font-family: Futura;
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      text-decoration: underline;
      z-index: 2;
      position: relative;
      display: inline-block;
      margin-top: 5px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      line-height: 1.375; }
    .big-slide._doctor .big-slide__text {
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      z-index: 2;
      position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow: hidden;
      line-height: 1.375; }
  .big-slide._text {
    width: 300px;
    padding: 30px;
    overflow: hidden;
    -webkit-box-shadow: 0 50px 60px rgba(218, 218, 218, 0.2);
    box-shadow: 0 50px 60px rgba(218, 218, 218, 0.2);
    border-radius: 10px;
    border: 1px solid #c6eee7;
    background-color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .big-slide._text .big-slide__title {
      color: #55566a;
      margin-bottom: 10px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      font-family: Futura;
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      text-decoration: underline;
      z-index: 2;
      position: relative;
      display: inline-block;
      margin-top: 5px; }
    .big-slide._text .big-slide__text {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow: hidden;
      position: relative;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      z-index: 2; }
  .big-slide._video, .big-slide._image {
    height: auto; }
    .big-slide._video .big-slide__text, .big-slide._image .big-slide__text {
      margin-top: 25px;
      color: #55566a;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      font-style: italic;
      line-height: 22px;
      max-width: 100%;
      max-width: 740px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .big-slide {
      height: 220px; }
      .big-slide._news {
        width: 335px;
        padding: 30px; }
        .big-slide._news .big-slide__title {
          font-size: 16px;
          line-height: 18px; }
        .big-slide._news .big-slide__text {
          font-size: 14px;
          line-height: 16px; }
        .big-slide._news .big-slide__img-blur {
          top: 120px; }
      .big-slide._doctor {
        width: 185px;
        padding: 15px; }
        .big-slide._doctor .btn {
          font-size: 14px;
          border-width: 2px;
          padding: 0px 8px;
          min-height: 25px; }
        .big-slide._doctor .big-slide__doctor {
          width: 68px;
          height: 68px;
          margin-top: -47px;
          margin-bottom: 7px;
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 68px;
          -ms-flex: 0 0 68px;
          flex: 0 0 68px; }
        .big-slide._doctor .big-slide__title {
          margin: 5px 0;
          font-size: 16px; }
        .big-slide._doctor .big-slide__text {
          font-size: 14px; }
      .big-slide._text {
        width: 185px;
        padding: 15px; }
        .big-slide._text .big-slide__title {
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 1.375; }
        .big-slide._text .big-slide__text {
          font-size: 14px;
          line-height: 1.375;
          overflow: hidden; } }
  @media (max-width: 767.98px) {
    .big-slide {
      height: 340px;
      max-width: calc(100vw - 30px); }
      .big-slide._news {
        padding: 15px; }
      .big-slide._doctor {
        padding: 15px; }
        .big-slide._doctor .big-slide__doctor {
          height: 100px;
          width: 100px;
          -webkit-flex-basis: 100px;
          -ms-flex-preferred-size: 100px;
          flex-basis: 100px;
          margin-top: 0; }
      .big-slide._text {
        padding: 15px; } }

.article {
  width: 550px;
  height: 300px;
  padding-left: 30px;
  margin-right: 30px;
  position: relative;
  z-index: 2; }
  .article:before {
    position: absolute;
    left: -60px;
    right: -60px;
    top: -10px;
    bottom: -110px;
    content: '';
    z-index: -2;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-image: url(../img/rect-bg-white-shadow.svg); }
  .article:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    z-index: -1;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-image: url(../img/rect-bg-white.svg); }
  .article__img {
    position: absolute;
    left: 0;
    top: 31px;
    z-index: 3;
    width: 155px;
    height: 155px;
    border-radius: 20px;
    overflow: hidden; }
  .article__info {
    width: 520px;
    height: 300px;
    padding: 30px 60px 30px 155px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    overflow: hidden; }
    .article__info-title {
      color: #55566a;
      font-family: Futura;
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
      text-decoration: underline;
      margin-bottom: 15px;
      max-height: 95px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical; }
    .article__info-text {
      color: #7d7e92;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 20px;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      overflow: hidden; }
    .article__info-date {
      color: #55566a;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto; }
    .article__info-text.textTailor p {
      margin: 0;
    }
@media (min-width: 992px) and (max-width: 1199.98px) {
  .article {
    width: 440px; }
  .article__img {
    width: 124px;
    height: 124px; }
  .article__info {
    width: 416px;
    padding-left: 124px; } }
@media (min-width: 768px) and (max-width: 991.98px) {
  .article {
    width: 330px; }
  .article__img {
    width: 93px;
    height: 93px; }
  .article__info {
    width: 312px;
    padding-left: 93px; } }
@media (max-width: 767.98px) {
  .article {
    max-width: calc( 100vw - 30px);
    padding: 0;
    height: 300px; }
  .article:before {
    left: -120px;
    right: -120px; }
  .article:after {
    left: -60px;
    right: -60px; }
  .article__img {
    width: 50px;
    height: 50px;
    border-radius: 10px; }
  .article__info {
    padding-left: 65px;
    padding-right: 0;
    height: 310px;
    max-width: 100%; }
  .article__info-title {
    font-size: 18px;
    line-height: 1.375;
    margin-bottom: 15px; }
  .article__info-text {
    margin-bottom: 10px;
    line-height: 1.375; } }

.slider-articles {
  padding-top: 50px; }
  .slider-articles .slick-prev,
  .slider-articles .slick-next {
  position: absolute;
    top: -20px;
    top: 30px; }
  .slider-articles .slick-next {
    left: 40px; }
  .slider-articles .slick-slide {
    outline: none; }
  .slider-articles .slick-list {
    overflow: visible; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .slider-articles .slick-list::before {
        position: absolute;
        content: '';
        z-index: 3;
        top: 0;
        bottom: 0;
        right: calc( 50% - 50vw);
        background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0)));
        background-image: -o-linear-gradient(right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        background-image: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        width: 290px; } }
    @media (max-width: 767.98px) {
      .slider-articles .article {
        height: 210px;
        margin: 0 75px 30px; }
      .slider-articles .article__info {
        height: 210px; } }
.feedback {
  width: 100%;
  height: 100%;
  padding: 0 40px 10px 40px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden; 
}
  .feedback:hover {
    cursor: pointer;
  }
  .feedback-wrap {
    height: 320px;
    width: 420px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 28px;
    z-index: 2;
    position: relative; 
    box-shadow: 0px 2px 4px 0px #00000026;
    border-radius: 30px;
    background-color: #ffffff;
  }

    .feedback-wrap:hover{
      transform: scale(1.06);
    }

    .feedback-wrap.feedback--about {
      height: 336px;
      width: 1240px;
    }
    .feedback-wrap:before {
      position: absolute;
      left: -60px;
      right: -60px;
      top: -10px;
      bottom: -110px;
      content: '';
      z-index: -2;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 50% 50%;
      pointer-events: none;
    }
    /* .feedback-wrap:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      z-index: -1;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 50% 50%;
      background-color: #ffffff;
      border-radius: 30px;
      pointer-events: none;
      box-shadow: none;
      transform: none;
    } */
    .feedback-wrap:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      z-index: -1;
      display: block;
      border-radius: 30px;
      pointer-events: none;
      box-shadow: none;
      transform: none;
    }
  .feedback_background {
    background-color: #EEF3F6; 
    padding-top: 10px;
    }
  .marg_title {
    margin-left: 26px;
  }
  .fs {
    font-size: 20px;
  }
  .marg_title2 {
    color: #f22c8b;
    margin-left: 0;
  }
  .read_all {
    display: flex; 
    margin-left: auto; 
    margin-right: 26px;
    font-size: 16px;
    font-weight: 700;
    padding: 0.375rem 0.75rem; 
    vertical-align: middle;
  }
  .img_feedback_more {
    width: 22px; 
    height: 22px;
  }
  .img_feedback_author {
    width: 72px; 
    margin-left: -30px;
  }
  .feedback_author {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    margin-left: 10px;
  }
  .feedback__title {
    color: #55566a;
    font-family: Futura;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    }
  .feedback__about {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
     
  }
    .feedback__about img {
      width: 56px;
      height: 56px;
      margin-right: -30px;
      border-radius: 50%; 
    }
    .feedback__about strong {
      font-size: 18px; }
  .feedback__text-wrap {
    position: relative;
    overflow: hidden;
    margin: 10px 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  .feedback__text, .feedback__text--full {
    color: #7d7e92;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    overflow: hidden;
    -webkit-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s; }
  .feedback__text {
    height: 100%;
    position: absolute; }
  .feedback__text--full {
    opacity: 0; }
  .feedback__more {
    color: #f22c8b;
    font-family: "TT Norms";
    font-size: 16px;
    line-height: 22px;
    margin-top: -15px;
    margin-bottom: 10px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    text-decoration: none;  
    font-weight:bold;
    margin-left: auto;
  }
  .feedback__date {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; }
  @media (max-width: 767.98px) {
    .feedback {
      padding: 0px 0 5px; }
    .feedback-wrap {
      height: 300px;
    }
    .feedback-wrap.feedback--about {
      height: 420px; 
    }
    .feedback-wrap:before {
      left: -120px;
      right: -120px; 
    }
 }

.slider-feedbacks {
}
  .slider-feedbacks.slick-initialized .slick-slide {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .slider-feedbacks .feedback {
    cursor: pointer; }
  .slider-feedbacks .slick-prev,
  .slider-feedbacks .slick-next {
    position: absolute;
    top: 30px; }
  .slider-feedbacks .slick-next {
    left: 40px; }
  .slider-feedbacks .slick-slide {
    outline: none; }
  .slider-feedbacks .slick-list {
    padding: 26px 0 26px 26px; 
  }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .slider-feedbacks .slick-list::before {
        position: absolute;
        content: '';
        z-index: 3;
        top: 0;
        bottom: 0;
        right: calc( 50% - 50vw);
        background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0)));
        background-image: -o-linear-gradient(right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        background-image: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        width: 290px; } }
  @media (max-width: 767.98px) {
    .slider-feedbacks {
      padding-top: 0; } }

.slider-feedbacks-fullsize-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .js--feedbacks-open .slider-feedbacks-fullsize-wrap {
    pointer-events: auto;
    opacity: 1; }
  .slider-feedbacks-fullsize-wrap .slider-feedbacks-fullsize-nav {
    width: auto;
    position: absolute;
    z-index: 8;
    left: 50%;
    top: 60px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 0; }
  .slider-feedbacks-fullsize-wrap .slider-feedbacks-fullsize {
    width: 100%;
    opacity: 0;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
    -webkit-transition: 0.3s 0.3s;
    -o-transition: 0.3s 0.3s;
    transition: 0.3s 0.3s; }
    .js--feedbacks-open .slider-feedbacks-fullsize-wrap .slider-feedbacks-fullsize {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1; }
    .slider-feedbacks-fullsize-wrap .slider-feedbacks-fullsize.slick-initialized .slick-slide {
      margin: 0 30px; }
  .slider-feedbacks-fullsize-wrap .slick-track {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .slider-feedbacks-fullsize-wrap .slick-list {
    overflow: visible; }
  .slider-feedbacks-fullsize-wrap .feedback-close {
    width: 17px;
    height: 17px;
    fill: #F22C8B;
    position: absolute;
    top: 32px;
    right: 32px;
    line-height: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
  }
    .slider-feedbacks-fullsize-wrap .feedback-close:hover {
      fill: #0ABAB5; }
  .slider-feedbacks-fullsize-wrap .feedback-wrap {
    outline: none;
    width: 540px;
    height: 320px;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    opacity: 0.8;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    max-height: calc(100vh - 200px); }
    .slider-feedbacks-fullsize-wrap .feedback-wrap.feedback--about {
      height: 440px; }
    .slider-feedbacks-fullsize-wrap .feedback-wrap .feedback {
      height: auto;
      max-height: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap .feedback__more {
        display: none; }
    .slider-feedbacks-fullsize-wrap .feedback-wrap.slick-center {
      opacity: 1;
      padding-top: 60px;
      height: calc(100vh - 200px);
      box-shadow: none; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap.slick-center .feedback-close {
        opacity: 1; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap.slick-center .feedback__text-wrap {
        height: auto; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap.slick-center .feedback__text {
        opacity: 0;
        pointer-events: none;
        height: 159px; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap.slick-center .feedback__text-wrap {
        height: 100%; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap.slick-center .feedback__text--full {
        opacity: 1;
        height: calc( 100% - 15px); }
  @media (max-width: 767.98px) {
    .slider-feedbacks-fullsize-wrap .slider-feedbacks-fullsize-nav {
      display: none; }
    .slider-feedbacks-fullsize-wrap .feedback-wrap {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      max-width: 100vw;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      padding-top: 0; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap::before, .slider-feedbacks-fullsize-wrap .feedback-wrap::after {
        display: none; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap .feedback-close {
        opacity: 1;
        top: 20px;
        right: 15px; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap.slick-center {
        padding-top: 20px;
        height: 100vh; }
      .slider-feedbacks-fullsize-wrap .feedback-wrap .feedback {
        background-color: #fff;
        padding: 15px; } }

.feedbacks-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -40px;
  position: relative;
  z-index: 2; }
  .feedbacks-row .feedback-wrap {
    margin-bottom: 1rem;
    margin-right: 70px;
    position: relative; }
    .feedbacks-row .feedback-wrap:nth-child(1) {
      z-index: 99; }
    .feedbacks-row .feedback-wrap:nth-child(2) {
      z-index: 98; }
    .feedbacks-row .feedback-wrap:nth-child(3) {
      z-index: 97; }
    .feedbacks-row .feedback-wrap:nth-child(4) {
      z-index: 96; }
    .feedbacks-row .feedback-wrap:nth-child(5) {
      z-index: 95; }
    .feedbacks-row .feedback-wrap:nth-child(6) {
      z-index: 94; }
    .feedbacks-row .feedback-wrap:nth-child(7) {
      z-index: 93; }
    .feedbacks-row .feedback-wrap:nth-child(8) {
      z-index: 92; }
    .feedbacks-row .feedback-wrap:nth-child(9) {
      z-index: 91; }
    .feedbacks-row .feedback-wrap:nth-child(10) {
      z-index: 90; }
    .feedbacks-row .feedback-wrap:nth-child(11) {
      z-index: 89; }
    .feedbacks-row .feedback-wrap:nth-child(12) {
      z-index: 88; }
    .feedbacks-row .feedback-wrap:nth-child(13) {
      z-index: 87; }
    .feedbacks-row .feedback-wrap:nth-child(14) {
      z-index: 86; }
    .feedbacks-row .feedback-wrap:nth-child(15) {
      z-index: 85; }
    .feedbacks-row .feedback-wrap:nth-child(16) {
      z-index: 84; }
    .feedbacks-row .feedback-wrap:nth-child(17) {
      z-index: 83; }
    .feedbacks-row .feedback-wrap:nth-child(18) {
      z-index: 82; }
    .feedbacks-row .feedback-wrap:nth-child(19) {
      z-index: 81; }
    .feedbacks-row .feedback-wrap:nth-child(20) {
      z-index: 80; }
    .feedbacks-row .feedback-wrap:nth-child(21) {
      z-index: 79; }
    .feedbacks-row .feedback-wrap:nth-child(22) {
      z-index: 78; }
    .feedbacks-row .feedback-wrap:nth-child(23) {
      z-index: 77; }
    .feedbacks-row .feedback-wrap:nth-child(24) {
      z-index: 76; }
    .feedbacks-row .feedback-wrap:nth-child(25) {
      z-index: 75; }
    .feedbacks-row .feedback-wrap:nth-child(26) {
      z-index: 74; }
    .feedbacks-row .feedback-wrap:nth-child(27) {
      z-index: 73; }
    .feedbacks-row .feedback-wrap:nth-child(28) {
      z-index: 72; }
    .feedbacks-row .feedback-wrap:nth-child(29) {
      z-index: 71; }
    .feedbacks-row .feedback-wrap:nth-child(30) {
      z-index: 70; }
    .feedbacks-row .feedback-wrap:nth-child(31) {
      z-index: 69; }
    .feedbacks-row .feedback-wrap:nth-child(32) {
      z-index: 68; }
    .feedbacks-row .feedback-wrap:nth-child(33) {
      z-index: 67; }
    .feedbacks-row .feedback-wrap:nth-child(34) {
      z-index: 66; }
    .feedbacks-row .feedback-wrap:nth-child(35) {
      z-index: 65; }
    .feedbacks-row .feedback-wrap:nth-child(36) {
      z-index: 64; }
    .feedbacks-row .feedback-wrap:nth-child(37) {
      z-index: 63; }
    .feedbacks-row .feedback-wrap:nth-child(38) {
      z-index: 62; }
    .feedbacks-row .feedback-wrap:nth-child(39) {
      z-index: 61; }
    .feedbacks-row .feedback-wrap:nth-child(40) {
      z-index: 60; }
    .feedbacks-row .feedback-wrap:nth-child(41) {
      z-index: 59; }
    .feedbacks-row .feedback-wrap:nth-child(42) {
      z-index: 58; }
    .feedbacks-row .feedback-wrap:nth-child(43) {
      z-index: 57; }
    .feedbacks-row .feedback-wrap:nth-child(44) {
      z-index: 56; }
    .feedbacks-row .feedback-wrap:nth-child(45) {
      z-index: 55; }
    .feedbacks-row .feedback-wrap:nth-child(46) {
      z-index: 54; }
    .feedbacks-row .feedback-wrap:nth-child(47) {
      z-index: 53; }
    .feedbacks-row .feedback-wrap:nth-child(48) {
      z-index: 52; }
    .feedbacks-row .feedback-wrap:nth-child(49) {
      z-index: 51; }
    .feedbacks-row .feedback-wrap:nth-child(50) {
      z-index: 50; }
    .feedbacks-row .feedback-wrap:nth-child(51) {
      z-index: 49; }
    .feedbacks-row .feedback-wrap:nth-child(52) {
      z-index: 48; }
    .feedbacks-row .feedback-wrap:nth-child(53) {
      z-index: 47; }
    .feedbacks-row .feedback-wrap:nth-child(54) {
      z-index: 46; }
    .feedbacks-row .feedback-wrap:nth-child(55) {
      z-index: 45; }
    .feedbacks-row .feedback-wrap:nth-child(56) {
      z-index: 44; }
    .feedbacks-row .feedback-wrap:nth-child(57) {
      z-index: 43; }
    .feedbacks-row .feedback-wrap:nth-child(58) {
      z-index: 42; }
    .feedbacks-row .feedback-wrap:nth-child(59) {
      z-index: 41; }
    .feedbacks-row .feedback-wrap:nth-child(60) {
      z-index: 40; }
    .feedbacks-row .feedback-wrap:nth-child(61) {
      z-index: 39; }
    .feedbacks-row .feedback-wrap:nth-child(62) {
      z-index: 38; }
    .feedbacks-row .feedback-wrap:nth-child(63) {
      z-index: 37; }
    .feedbacks-row .feedback-wrap:nth-child(64) {
      z-index: 36; }
    .feedbacks-row .feedback-wrap:nth-child(65) {
      z-index: 35; }
    .feedbacks-row .feedback-wrap:nth-child(66) {
      z-index: 34; }
    .feedbacks-row .feedback-wrap:nth-child(67) {
      z-index: 33; }
    .feedbacks-row .feedback-wrap:nth-child(68) {
      z-index: 32; }
    .feedbacks-row .feedback-wrap:nth-child(69) {
      z-index: 31; }
    .feedbacks-row .feedback-wrap:nth-child(70) {
      z-index: 30; }
    .feedbacks-row .feedback-wrap:nth-child(71) {
      z-index: 29; }
    .feedbacks-row .feedback-wrap:nth-child(72) {
      z-index: 28; }
    .feedbacks-row .feedback-wrap:nth-child(73) {
      z-index: 27; }
    .feedbacks-row .feedback-wrap:nth-child(74) {
      z-index: 26; }
    .feedbacks-row .feedback-wrap:nth-child(75) {
      z-index: 25; }
    .feedbacks-row .feedback-wrap:nth-child(76) {
      z-index: 24; }
    .feedbacks-row .feedback-wrap:nth-child(77) {
      z-index: 23; }
    .feedbacks-row .feedback-wrap:nth-child(78) {
      z-index: 22; }
    .feedbacks-row .feedback-wrap:nth-child(79) {
      z-index: 21; }
    .feedbacks-row .feedback-wrap:nth-child(80) {
      z-index: 20; }
    .feedbacks-row .feedback-wrap:nth-child(81) {
      z-index: 19; }
    .feedbacks-row .feedback-wrap:nth-child(82) {
      z-index: 18; }
    .feedbacks-row .feedback-wrap:nth-child(83) {
      z-index: 17; }
    .feedbacks-row .feedback-wrap:nth-child(84) {
      z-index: 16; }
    .feedbacks-row .feedback-wrap:nth-child(85) {
      z-index: 15; }
    .feedbacks-row .feedback-wrap:nth-child(86) {
      z-index: 14; }
    .feedbacks-row .feedback-wrap:nth-child(87) {
      z-index: 13; }
    .feedbacks-row .feedback-wrap:nth-child(88) {
      z-index: 12; }
    .feedbacks-row .feedback-wrap:nth-child(89) {
      z-index: 11; }
    .feedbacks-row .feedback-wrap:nth-child(90) {
      z-index: 10; }
    .feedbacks-row .feedback-wrap:nth-child(91) {
      z-index: 9; }
    .feedbacks-row .feedback-wrap:nth-child(92) {
      z-index: 8; }
    .feedbacks-row .feedback-wrap:nth-child(93) {
      z-index: 7; }
    .feedbacks-row .feedback-wrap:nth-child(94) {
      z-index: 6; }
    .feedbacks-row .feedback-wrap:nth-child(95) {
      z-index: 5; }
    .feedbacks-row .feedback-wrap:nth-child(96) {
      z-index: 4; }
    .feedbacks-row .feedback-wrap:nth-child(97) {
      z-index: 3; }
    .feedbacks-row .feedback-wrap:nth-child(98) {
      z-index: 2; }
    .feedbacks-row .feedback-wrap:nth-child(99) {
      z-index: 1; }
    .feedbacks-row .feedback-wrap:nth-child(100) {
      z-index: 0; }
  @media (max-width: 991.98px) {
    .feedbacks-row {
      margin: 0;
     }
    .feedbacks-row .feedback-wrap {
      width: 100%;
      margin-bottom: 20px;
     }
    .feedbacks-row .feedback-wrap .feedback {
      padding: 0 10px;
     }
    }


.map {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 580px;
  margin: 60px 0;
  width: 1240px;
}
  .map__mobile {
    display: none; }
  .map__info {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 440px;
    -ms-flex: 0 0 440px;
    flex: 0 0 440px;
    max-width: 41vh; }
  .map__search {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    height: 70px; }
    .map__search-inner {
      position: relative;
      margin: 0 auto;
      width: 350px; }
    .map__search input {
      padding: 0 40px 0 17px;
      width: 100%;
      height: 40px;
      border-radius: 20px;
      border: 2px solid #eeeeee;
      background-color: #ffffff; }
    .map__search button {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background: none;
      border: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s; }
      .map__search button svg {
        width: 15px;
        height: 15px;
        fill: #7d7e92; }
        .map__search button svg:hover {
          fill: #F22C8B; }
  .map__list {
    height: 580px; }
    .map__list .baron__scroller {
      padding: 0 40px 0 33px; }
    .map__list .baron__track,
    .map__list .baron__free {
      right: auto;
      left: 0; }
  .map__address {
    padding: 15px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    position: relative;
    border-radius: 10px;
    cursor: pointer; }
    .map__address + .map__address {
      margin-top: 1px; }
      .map__address + .map__address::before {
        position: absolute;
        content: "";
        display: block;
        border-top: 1px solid #eeeeee;
        top: -1px;
        left: 0;
        right: 0; }
    .map__address:hover {
      background-color: rgba(88, 207, 203, 0.3); }
    .map__address.active {
      background-color: #0ABAB5; }
      .map__address.active .map__address-title,
      .map__address.active .map__address-address {
        color: #fff; }
      .map__address.active::before,
      .map__address.active + .map__address::before {
        display: none; }
    .map__address-title {
      color: #55566a;
      font-family: "TT Norms";
      font-size: 22px;
      font-weight: 700;
      line-height: 24px; }
    .map__address-address {
      color: #7d7e92;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px; }
  .map__map {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
    .map__map svg {
      pointer-events: none;
      position: absolute;
      z-index: 3;
      top: 0;
      bottom: 0;
      left: 0;
      right: -10%;
      max-width: none;
      height: 100%;
      width: 110%;
      fill: white; }
    .map__map #map {
      height: 100%; }
  @media (max-width: 991.98px) {
    .map__info {
      -webkit-flex-basis: 400px;
      -ms-flex-preferred-size: 400px;
      flex-basis: 400px; }
      .map__info .baron__scroller {
        padding-left: 15px; }
    .map__map svg {
      right: -250%;
      width: 250%; } }
  @media (max-width: 767.98px) {
    .map {
      display: none; } }

.map-object {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .map-object__link {
    width: 125px;
    height: 33px;
    line-height: 33px;
    border-radius: 29px;
    background-color: #44bbb7;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500; }
  .map-object__address {
    padding: 8px 20px;
    height: 38px;
    -webkit-box-shadow: 0 40px 50px rgba(0, 0, 0, 0.1);
    box-shadow: 0 40px 50px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    background-color: #ffffff;
    margin-bottom: 10px;
    white-space: nowrap;
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; }
  .map-object__marker {
    width: 54px;
    height: 76px; }

.doctor {
  padding: 25px 0;
  padding-left: 135px;
  position: relative;
  min-height: 165px; }
  .doctor + .doctor {
    border-top: 1px solid #f8f8f8; }
  .doctor__img {
    left: 0;
    top: 25px;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute; }
  .doctor__name {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 22px;
    font-weight: 700;
    line-height: 22px; }
  .doctor__role {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 5px; }
  .doctor__description {
    margin-top: 15px;
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; }
  .doctor__actions .row > * {
    margin-top: 20px; }
  .doctor__actions .big {
    font-size: 22px;
    line-height: 24px; }
  @media (max-width: 767.98px) {
    .doctor {
      padding-left: 0;
      display: inline-block;
    }
      .doctor__img {
        position: static;
        width: 90px;
        height: 90px;
        float: left;
        margin-right: 15px;
        margin-bottom: 15px; }
      .doctor__name {
        float: left;
        width: calc( 100% - 136px); }
      .doctor__role {
        float: left;
        width: calc( 100% - 136px); }
      .doctor__description {
        clear: both;
        width: 100%; } }
.doctor-card {
  min-height: 270px; /* добавлено */
}
.doctor-card__bg {
  position: absolute;
  z-index: -1;
  left: -90px;
  top: 60px;
  right: 5px;
  bottom: 0; }
  .doctor-card__bg svg {
    height: 420px;
    width: 100%; }

.doctor-card__actions {
  width: 165px; }

.doctor-card__text {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 24px;
  max-width: 200px; }

.doctor-card__img {
  margin-bottom: 24px;
  width: 165px;
  height: 165px;
  /*border-radius: 50%;*/
  overflow: hidden; }

.doctor-card__socials {
  margin-top: 24px; }
  .doctor-card__socials a {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
    font-size: 0;
    width: 23px;
    height: 23px;
    margin-right: 10px; }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .doctor-card {
    min-height: 220px; /* добавлено */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    max-height: 220px;
    margin-bottom: 60px; }
    .doctor-card__bg {
      display: none; }
    .doctor-card__img {
      width: 35%;
      padding-right: calc(35% - 165px); }
    .doctor-card__actions {
      margin-right: 60px; }
      .doctor-card__actions-feedback {
        position: absolute;
        left: calc( 35% + 165px + 60px);
        top: 70px; }
    .doctor-card__text {
      position: absolute;
      left: 35%;
      top: 115px;
      margin: 0;
      font-weight: bold; }
    .doctor-card__socials {
      margin-top: 0; }
      .doctor-card__socials a {
        margin-right: 35px;
        width: 35px;
        height: 35px; } }

@media (max-width: 767.98px) {
  .doctor-card {
    min-height: 200px; /* добавлено */
    width: 100%;
    position: relative;
    padding-bottom: 40px;
    text-align: center;
    margin-bottom: 36px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .doctor-card__bg {
      display: none; }
    .doctor-card__img {
      width: 110px;
      height: 110px;
      margin-left: auto;
      margin-right: auto; }
    .doctor-card__actions {
      width: 240px;
      margin-left: auto;
      margin-right: auto; }
      .doctor-card__actions-feedback {
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%); }
    .doctor-card__text {
      max-width: 100%;
      font-weight: bold;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1; }
    .doctor-card__socials {
      margin-top: 0;
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
      -ms-flex-order: 2;
      order: 2; }
      .doctor-card__socials a {
        width: 35px;
        height: 35px; }
        .doctor-card__socials a + a {
          margin-left: 35px; } }

.tags {
  margin: 15px -5px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .tags__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%; }
  .tags__action {
    color: #F22C8B;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-decoration: underline;
    margin-left: 5px;
    margin-top: 10px;
    margin-right: 15px; }
    @media screen and (min-width: 1480px) {
      .tags__action {
        margin: 0;
        position: absolute;
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        left: -25px;
        top: 9px; } }
  .tags__edit, .tags__edited-actions {
    display: none; }
  .tags__item {
    padding: 0;
    display: block; }
    .tags__item span {
      color: #55566a;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin: 5px;
      padding: 5px 14px 3px;
      cursor: pointer;
      display: block;
      height: 30px;
      border-radius: 20px;
      background-color: #eefdfa;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s; }
      @media (min-width: 992px) {
        .tags__item span:hover {
          background-color: #0ABAB5;
          color: #fff; } }
    .tags__item input:checked + span {
      background-color: #F22C8B;
      color: #fff; }
      @media (min-width: 992px) {
        .tags__item input:checked + span:hover {
          background-color: #bc1039; } }
  @media (max-width: 767.98px) {
    .tags__edit {
      display: block; }
      .tags__edit::before {
        content: '';
        top: 0;
        bottom: 30px;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 4; }
    .tags__item span {
      display: none; }
    .tags__item input:checked + span {
      display: block; }
    .tags.js--edited {
      max-width: 100vw;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      position: fixed;
      z-index: 9999;
      padding: 15px;
      margin: 0;
      overflow: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff; }
      .tags.js--edited .tags__list {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center; }
      .tags.js--edited .tags__edit {
        display: none; }
      .tags.js--edited .tags__edited-actions {
        display: block;
        text-align: center; }
      .tags.js--edited .tags__item span {
        display: block; } }

.documents-need {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .documents-need__item {
    width: 200px;
    margin: 15px;
    position: relative;
    padding-top: 154px; }
  .documents-need__img {
    top: 70px;
    left: 40%;
    -webkit-transform: translate(-50%, -50%) rotate(-15deg);
    -ms-transform: translate(-50%, -50%) rotate(-15deg);
    transform: translate(-50%, -50%) rotate(-15deg);
    margin: auto;
    position: absolute;
    z-index: 3;
    max-width: 80%; }
    .documents-need__img::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      top: 25px;
      width: 100%;
      height: 100%;
      background-color: rgba(86, 212, 191, 0.5);
      -webkit-filter: blur(30px);
      filter: blur(30px); }
    .documents-need__img::after {
      content: "";
      position: absolute;
      z-index: -2;
      width: 150px;
      height: 150px;
      display: block;
      left: 50%;
      top: 80%;
      -webkit-transform: translate(-50%, -50%) rotate(15deg);
      -ms-transform: translate(-50%, -50%) rotate(15deg);
      transform: translate(-50%, -50%) rotate(15deg);
      background-image: url(../img/rect-blur-bg.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%; }
  .documents-need__title {
    position: relative;
    z-index: 6;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px; }
  @media (max-width: 767.98px) {
    .documents-need__item {
      max-width: calc(50% - 30px); }
    .documents-need__img {
      max-width: 100%;
      left: 50%; }
      .documents-need__img::after {
        max-width: 35vw;
        max-height: 35vw; }
    .documents-need__title {
      text-align: center;
      font-size: 18px;
      line-height: 20px; } }

.page-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -30px; }
  .page-actions > * {
    margin: 10px 30px;
    color: #F22C8B;
    display: block;
    cursor: pointer; }
    @media (min-width: 992px) {
      .page-actions > *:hover {
        color: #F22C8B; } }
    .page-actions > * > svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      fill: #F22C8B; }
  @media (max-width: 991.98px) {
    .page-actions {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 7.5px -15px; }
      .page-actions > * {
        margin: 7.5px 15px; } }
  .page-actions__back {
    position: relative; }
    .page-actions__back > svg {
      width: 25px;
      height: 25px; }
      @media (min-width: 1300px) {
        .page-actions__back > svg {
          position: absolute;
          left: -50px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%); }
      }
  @media (max-width: 767.98px) {
    .page-actions__print {
      display: none; } }
  .page-actions__share {
    position: relative;
    z-index: 3;}
    .page-actions__share:hover .page-actions__share-inner {
      /*width: max-content;*/
      opacity: 1;
      pointer-events: auto;
      -webkit-transform: translateX(-50%) translateY(0);
      -ms-transform: translateX(-50%) translateY(0);
      transform: translateX(-50%) translateY(0);
    }
    .page-actions__share-inner {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      position: absolute;
      top: 100%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(15px);
      -ms-transform: translateX(-50%) translateY(15px);
      transform: translateX(-50%) translateY(15px);
      border: 2px solid #F22C8B;
      padding: 15px;
      border-radius: 5px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      pointer-events: none;
      opacity: 0;
      background-color: #fff; }
      .page-actions__share-inner > * {
        margin: 5px; }
      .page-actions__share-inner svg {
        max-width: 23px;
        max-height: 23px; }
      .page-actions__share-inner ._link {
        max-width: 280px;
        overflow: hidden;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        color: #F22C8B;
        -webkit-transition: 0.05s;
        -o-transition: 0.05s;
        transition: 0.05s;
        position: relative;
        padding-right: 30px;
        line-height: 24px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }
        .page-actions__share-inner ._link svg {
          max-width: 24px;
          max-height: 24px;
          position: absolute;
          top: 0;
          right: 0;
          fill: #F22C8B;
          -webkit-transition: 0.05s;
          -o-transition: 0.05s;
          transition: 0.05s; }
        .page-actions__share-inner ._link:active {
          color: #0ABAB5; }
          .page-actions__share-inner ._link:active svg {
            fill: #0ABAB5; }

.book-consult__bg {
  position: absolute;
  z-index: -1;
  left: -80px;
  top: -30px;
  right: -80px;
  bottom: 0; }
  .book-consult__bg svg {
    height: 370px;
    width: 100%; }

.book-consult__price {
  font-size: 40px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 15px; }

.book-consult__action {
  margin-bottom: 15px; }
  .book-consult__action a {
    height: 63px;
    padding: 8px 15px;
    line-height: 20px;
    border-radius: 32px;
    margin-left: -15px;
    width: 185px; }

.book-consult__text {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.book-consult__doctor-img {
  margin-bottom: 15px;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  overflow: hidden; }

.book-consult__doctor-name {
  width: 10px;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 22px;
  font-weight: bold; }

.book-consult__doctor-role {
  color: #55566a; }

@media screen and (min-width: 992px) {
  .book-consult {
    min-height: 210px;
    width: 170px;
    position: absolute;
    top: 60px;
    left: calc( 100% + 33.33% - 15px - 170px);
    z-index: 2; } }

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .book-consult {
    min-height: 210px;
    float: right;
    width: 200px;
    margin: 30px 0 30px 100px;
    position: relative; } }

@media print {
  .book-consult {
    float: right;
    clear: both;
    width: 200px;
    margin: 30px 0 30px 100px;
    position: relative; }
    .book-consult__action {
      display: none; } }

@media (max-width: 767.98px) {
  .book-consult {
    min-height: 210px;
    width: 100%;
    position: relative;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0 15vw 0 10vw; }
    .book-consult__bg {
      left: -150px; }
    .book-consult__action a {
      width: 100%;
      max-width: 240px;
    }
}

.faq {
  margin: 36px 0;
  text-decoration: none;
  color: #55566a;
  display: block;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s; }
  .faq:hover {
    text-decoration: none; }
  .faq + .faq {
    padding-top: 36px;
    border-top: 1px solid #f8f8f8; }
  .faq-question__text {
    font-size: 22px;
    font-weight: 500;
    line-height: 27px; }
  .faq-question__info {
    margin-top: 22px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; }
  .faq-question__link {
    margin-top: 22px; }
    @media (min-width: 768px) {
      .faq-question__link {
        display: none; } }

.faq-details {
  position: relative;
  padding-right: 280px;
  margin-top: 22px; }
  .faq-details__question-text {
    color: #55566a;
    font-family: Futura;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 22px; }
  .faq-details__question-info {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 22px; }
  .faq-details__answer {
    margin-top: 22px; }
    .faq-details__answer-text {
      margin-top: 22px;
      color: #55566a;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px; }
  .faq-details__moderator-bg {
    position: absolute;
    z-index: -1;
    left: -90px;
    top: -30px;
    right: -80px; }
    .faq-details__moderator-bg svg {
      height: 320px;
      width: 100%; }
  .faq-details__moderator-img {
    margin-bottom: 15px;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    overflow: hidden; }
  .faq-details__moderator-name {
    width: 10px;
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold; }
  .faq-details__moderator-role {
    color: #55566a; }
  @media (min-width: 768px) {
    .faq-details__moderator {
      width: 170px;
      position: absolute;
      top: 60px;
      right: 0;
      z-index: 2; } }
  @media (max-width: 767.98px) {
    .faq-details {
      padding-right: 0; }
      .faq-details__moderator {
        width: 100%;
        position: relative;
        min-height: 115px;
        padding-left: 130px;
        margin-bottom: 30px; }
        .faq-details__moderator-bg {
          display: none; }
        .faq-details__moderator-img {
          position: absolute;
          left: 0;
          top: 0; }
        .faq-details__moderator-name {
          font-size: 18px;
          line-height: 20px; } }

.promo {
  width: 100%;
  height: 370px;
  border-radius: 50px;
  background-color: #ffffff;
  padding: 45px 55px;
  position: relative;
  z-index: 4;
  -webkit-box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05);
  box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05); }
  .promo + .promo {
    margin-top: 45px; }
  .promo::before {
    content: "";
    position: absolute;
    z-index: 7;
    top: 0;
    bottom: 0;
    right: 0;
    width: 384px;
    border-radius: 50px;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#ffffff));
    background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%); }
  .promo__wrap-img {
    overflow: hidden;
    border-radius: 50px; }
  .promo__img {
    -webkit-transform: translateZ(0);
    transform: translateZ(0); }
  .promo__img, .promo__img-blur {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    border-radius: 50px;
    overflow: hidden; }
    .promo__img img, .promo__img-blur img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 0% 50%;
      object-position: 0% 50%; }
  .promo__img-blur {
    top: 130px;
    border-radius: 0 0 100px 100px;
    z-index: 3;
    -webkit-filter: blur(50px);
    filter: blur(50px); }
    .promo__img-blur img {
      max-height: calc( 100% + 130px);
      margin-top: -130px;
      height: calc( 100% + 150px); }
  .promo__info {
    max-width: 680px;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .promo__title {
    margin-bottom: 12px;
    color: #ffffff;
    font-family: Futura;
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.3); }
  .promo__text {
    color: #ffffff;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    max-height: 110px; }
  .promo__price {
    color: #55566a;
    font-family: Futura;
    font-size: 40px;
    font-weight: 700;
    line-height: 43px;
    position: absolute;
    z-index: 8;
    right: 70px;
    bottom: 40px; }
  .promo__action {
    margin-top: 20px; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .promo::before {
      width: 350px; }
    .promo__info {
      max-width: 600px; } }
  @media (max-width: 991.98px) {
    .promo {
      height: auto;
      padding: 0;
      background: none;
      -webkit-box-shadow: none;
      box-shadow: none; }
      .promo__img {
        position: static;
        margin-bottom: 20px; }
      .promo::before, .promo__img-blur {
        display: none; }
      .promo__title, .promo__text {
        color: #55566a;
        text-shadow: none; }
      .promo__price {
        right: 0;
        bottom: 0; } }
  @media (max-width: 767.98px) {
    .promo__wrap-img, .promo__img {
      border-radius: 20px; }
    .promo__img {
      margin-bottom: 10px; }
    .promo__title {
      font-size: 18px; }
    .promo__price {
      font-size: 22px;
      line-height: 22px;
      margin-top: -10px;
      margin-bottom: 5px;
      position: static; }
    .promo__text {
      max-height: 88px; }
    .promo__action {
      margin-top: 10px; }
      .promo__action .btn {
        display: block; }
    .promo + .promo {
      margin-top: 20px; } }

.big-slider .promo {
  margin-right: 44px; }
  @media (min-width: 1200px) {
    .big-slider .promo {
      width: 1120px; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .big-slider .promo {
      width: 930px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .big-slider .promo {
      width: 690px; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .big-slider .promo {
      width: 510px; } }
  @media (max-width: 575.98px) {
    .big-slider .promo {
      width: calc( 100vw - 30px); } }
  .big-slider .promo + .promo {
    margin-top: 0; }

.callback {
  position: relative;
  z-index: 3;
  padding: 50px 0 20px;
  margin: 45px 0; }
  @media (max-width: 767.98px) {
    .callback {
      margin: 30px 0; } }
  .callback-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -180px;
    left: -180px;
    z-index: -1; }
    .callback-bg img {
      height: 100%;
      width: 100%; }
  .callback form {
    width: 360px;
    max-width: 100%;
    margin: auto; }

.search-hint {
  -webkit-box-shadow: 0 130px 75px rgba(11, 17, 26, 0.1);
  box-shadow: 0 130px 75px rgba(11, 17, 26, 0.1);
  border-radius: 20px;
  background-color: #f8f8f8;
  width: 1200px;
  margin: auto; }
  @media (min-width: 1200px) {
    .search-hint {
      margin-bottom: 200px; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .search-hint {
      margin-bottom: 100px; } }
  .search-hint-wrap {
    position: fixed;
    z-index: 1112;
    left: 0;
    right: 0;
    top: 200px;
    bottom: 0;
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
  .search-hint__row {
    background-color: #ffffff; }
    @media (min-width: 1200px) {
      .search-hint__row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        padding: 25px 10px; } }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .search-hint__row {
        padding: 25px 30px; } }
    @media (max-width: 767.98px) {
      .search-hint__row {
        display: none; } }
  @media (min-width: 1200px) {
    .search-hint__col {
      padding: 0 25px;
      width: 25%;
      height: 700px; }
      .search-hint__col .baron {
        height: 648px;
        position: relative; }
        .search-hint__col .baron._scrollbar .baron__scroller {
          padding-right: 15px; }
        .search-hint__col .baron .baron__free {
          width: 5px;
          background-color: rgba(88, 207, 203, 0.3); }
        .search-hint__col .baron .baron__bar {
          border-radius: 3px;
          width: 100%;
          background-color: #0ABAB5;
          display: block; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .search-hint__col .baron {
      margin: 0 -30px; }
      .search-hint__col .baron._scrollbar {
        position: relative; }
        .search-hint__col .baron._scrollbar::after {
          position: absolute;
          content: "";
          display: block;
          width: 280px;
          right: -1px;
          top: 0;
          bottom: 0;
          background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0)));
          background-image: -o-linear-gradient(right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
          background-image: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
          pointer-events: none; }
        .search-hint__col .baron._scrollbar .baron__scroller::after {
          content: "";
          display: block; } }
      @media (min-width: 768px) and (max-width: 1199.98px) and (min-width: 992px) and (max-width: 1199.98px) {
        .search-hint__col .baron._scrollbar .baron__scroller::after {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 271px;
          -ms-flex: 0 0 271px;
          flex: 0 0 271px; } }
      @media (min-width: 768px) and (max-width: 1199.98px) and (min-width: 768px) and (max-width: 991.98px) {
        .search-hint__col .baron._scrollbar .baron__scroller::after {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 250px;
          -ms-flex: 0 0 250px;
          flex: 0 0 250px; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
      .search-hint__col .baron .baron__track {
        display: none; }
      .search-hint__col .baron .baron__scroller {
        padding: 0 30px 30px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex; } }
  .search-hint__content {
    padding: 25px 35px; }
    @media (max-width: 767.98px) {
      .search-hint__content {
        display: none; } }
  @media (min-width: 768px) {
    .search-hint__mobile {
      display: none; } }
  @media (min-width: 1200px) {
    .search-hint {
      width: 1240px;
      -webkit-transform: translateX(-15px);
      -ms-transform: translateX(-15px);
      transform: translateX(-15px); } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .search-hint {
      width: 930px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .search-hint {
      width: 690px; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .search-hint-wrap {
      top: 100px; } }
  @media (max-width: 767.98px) {
    .search-hint {
      width: 100%; }
      .search-hint-wrap {
        top: 220px; } }

.js--active-results {
  overflow: hidden; }
  .js--active-results .mainmenu,
  .js--active-results .mobilemenu {
    overflow: hidden; }
  .js--active-results.js--open-menu .mainmenu > .container > * {
    opacity: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s; }
  .js--active-results.js--open-menu .mainmenu .mainmenu-top {
    opacity: 1; }
  .js--active-results .search-hint-wrap {
    opacity: 1;
    pointer-events: auto; }
    .js--active-results .search-hint-wrap .search-hint {
      pointer-events: auto; }

.hint-doctor {
  text-decoration: none !important;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
  color: #55566a;
  font-family: "TT Norms";
  display: block; }
  .hint-doctor + .hint-doctor {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #f8f8f8; }
  .hint-doctor__img {
    border-radius: 50%;
    overflow: hidden;
    width: 115px;
    height: 115px;
    margin-bottom: 15px; }
  .hint-doctor__name {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px; }
  .hint-doctor__role {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; }

.hint-center {
  text-decoration: none !important;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
  color: #55566a;
  font-family: "TT Norms";
  display: block; }
  .hint-center + .hint-center {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #f8f8f8; }

.hint-service {
  text-decoration: none !important;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
  color: #55566a;
  font-family: "TT Norms";
  display: block; }
  .hint-service + .hint-service {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #f8f8f8; }
  .hint-service__price {
    color: #55566a;
    font-family: Futura;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 10px; }

.hint-document {
  text-decoration: none !important;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
  color: #55566a;
  font-family: "TT Norms";
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .hint-document + .hint-document {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #f8f8f8; }
  .hint-document__icon {
    width: 31px;
    height: 43px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 31px;
    -ms-flex: 0 0 31px;
    flex: 0 0 31px;
    margin-right: 15px; }
  .hint-document__title {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center; }

.hint-faq {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #55566a;
  text-decoration: none !important;
  display: block; }
  .hint-faq__answer, .hint-faq__question {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative; }
  @media (min-width: 768px) {
    .hint-faq {
      margin-bottom: 30px; }
      .hint-faq__answer, .hint-faq__question {
        max-height: 66px;
        padding-left: 45px;
        -webkit-line-clamp: 2; }
        .hint-faq__answer::before, .hint-faq__question::before {
          font-weight: bold;
          position: absolute;
          left: 0;
          top: 50%;
          font-size: 30px;
          line-height: 30px;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          color: #7d7e92; }
      .hint-faq__question {
        margin-bottom: 10px; }
        .hint-faq__question::before {
          content: "Q:"; }
      .hint-faq__answer::before {
        content: "A:"; } }
  @media (max-width: 767.98px) {
    .hint-faq__answer, .hint-faq__question {
      max-height: 33px;
      -webkit-line-clamp: 1; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .hint-doctor,
  .hint-center,
  .hint-service,
  .hint-document {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 190px;
    -ms-flex: 0 0 190px;
    flex: 0 0 190px; }
    .hint-doctor + .hint-doctor,
    .hint-center + .hint-doctor,
    .hint-service + .hint-doctor,
    .hint-document + .hint-doctor, .hint-doctor +
    .hint-center,
    .hint-center +
    .hint-center,
    .hint-service +
    .hint-center,
    .hint-document +
    .hint-center, .hint-doctor +
    .hint-service,
    .hint-center +
    .hint-service,
    .hint-service +
    .hint-service,
    .hint-document +
    .hint-service, .hint-doctor +
    .hint-document,
    .hint-center +
    .hint-document,
    .hint-service +
    .hint-document,
    .hint-document +
    .hint-document {
      margin-top: 0;
      padding-top: 0;
      border: none;
      margin-left: 30px; } }

@media (max-width: 767.98px) {
  .search-hint__mobile {
    padding: 15px;
    background-color: #fff;
    margin-top: -30px; }
  .hint-doctor {
    position: relative;
    padding-left: 66px;
    min-height: 46px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .hint-doctor__img {
      width: 46px;
      height: 46px;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(calc(-50% + 15px));
      -ms-transform: translateY(calc(-50% + 15px));
      transform: translateY(calc(-50% + 15px)); }
    .hint-doctor__name {
      font-size: 13px;
      line-height: 16px; }
    .hint-doctor__role {
      font-size: 10px;
      line-height: 12px; }
  .hint-center,
  .hint-service__title,
  .hint-document__title {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 66px; }
  .hint-doctor,
  .hint-center,
  .hint-service,
  .hint-document,
  .hint-faq {
    position: relative;
    line-height: 16px;
    padding-top: 30px; }
    .hint-doctor:not(:first-child)::before,
    .hint-center:not(:first-child)::before,
    .hint-service:not(:first-child)::before,
    .hint-documents:not(:first-child)::before,
    .hint-faq:not(:first-child)::before {
      position: absolute;
      content: "";
      top: 14px;
      left: 0;
      right: 0;
      background-color: #f8f8f8;
      height: 2px; }
  .hint-service {
    padding-right: 120px; }
    .hint-service__price {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(calc( -50% + 15px));
      -ms-transform: translateY(calc( -50% + 15px));
      transform: translateY(calc( -50% + 15px));
      -webkit-transform: translateY(calc( -50% + 7px));
      -ms-transform: translateY(calc( -50% + 7px));
      transform: translateY(calc( -50% + 7px));
      right: 30px;
      font-size: 16px;
      font-family: "TT Norms"; }
  .hint-mobile-arrow {
    font-size: 0;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(calc( -50% + 15px)) rotate(-90deg);
    -ms-transform: translateY(calc( -50% + 15px)) rotate(-90deg);
    transform: translateY(calc( -50% + 15px)) rotate(-90deg);
    width: 16px;
    height: 10px;
    fill: #eee; } }

.ask-question__bg {
  position: absolute;
  z-index: -1;
  left: -80px;
  top: -30px;
  right: -80px;
  bottom: 0; }
  .ask-question__bg svg {
    height: 370px;
    width: 100%; }

.ask-question__action {
  margin: 15px 0; }

.ask-question__img {
  margin-bottom: 15px;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  overflow: hidden; }

.ask-question__name {
  width: 10px;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 22px;
  font-weight: bold; }

.ask-question__role {
  color: #55566a; }

@media screen and (min-width: 992px) {
  .ask-question {
    width: 170px;
    position: absolute;
    top: 60px;
    left: calc(100% + 33.33% - 15px - 170px);
    z-index: 2;
  }
  #center-about .col-lg-9,
  #clinic-about .col-lg-9 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  #center-about .ask-question,
  #clinic-about .ask-question {
    /*width: 200px;*/
    position: relative;
    float: right;
    top: initial;
    left: initial;
    margin: 30px 0 30px 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .ask-question {
    float: right;
    width: 200px;
    margin: 30px 0 30px 100px;
    position: relative; } }

@media print {
  .ask-question {
    float: right;
    clear: both;
    width: 200px;
    margin: 30px 0 30px 100px;
    position: relative; }
    .ask-question__action {
      display: none; } }

@media (max-width: 767.98px) {
  .ask-question {
    width: 100%;
    position: relative;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0 15vw 0 10vw; }
    .ask-question__bg {
      left: -180px; }
    .ask-question__action a {
      width: 100%;
      max-width: 240px; } }

.employee {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-top: 40px; }
  .employee + .employee {
    border-top: 1px solid  #f8f8f8;
    padding-top: 44px; }
  .employee__img {
    width: 115px;
    height: 115px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 115px;
    -ms-flex: 0 0 115px;
    flex: 0 0 115px;
    max-width: 115px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px; }
  .employee__name {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 22px;
    font-weight: 700;
    line-height: 22px; }
  .employee__action {
    display: none; }
  .employee__social {
    width: 300px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 300px;
    -ms-flex: 0 0 300px;
    flex: 0 0 300px;
    max-width: 300px;
    padding-left: 30px; }
    .employee__social a {
      margin: 5px;
      width: 18px;
      height: 18px;
      display: inline-block; }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .employee {
      position: relative; }
      .employee__social {
        position: absolute;
        top: 130px;
        left: 0;
        width: 115px;
        padding-left: 0; } }
  @media (max-width: 767.98px) {
    .employee {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      position: relative;
      padding-left: 105px;
      margin-top: 22px; }
      .employee + .employee {
        padding-top: 22px; }
        .employee + .employee .employee__img {
          top: 22px; }
      .employee__img {
        left: 0;
        top: 0;
        position: absolute;
        width: 90px;
        height: 90px; }
      .employee__action {
        display: block; }
        .employee__action ._close {
          display: none; }
        .js--opened .employee__action ._open {
          display: none; }
        .js--opened .employee__action ._close {
          display: block; }
      .employee__text {
        display: none;
        margin-left: -105px; }
        .employee__text .employee__action {
          padding-left: 105px; }
      .employee__social {
        margin-top: 10px;
        margin-left: -5px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-left: 0; } }
.employee-card {
  min-height: 270px;
}
.employee-card__bg {
  position: absolute;
  z-index: -1;
  left: -90px;
  top: 60px;
  right: 5px;
  bottom: 0; }
  .employee-card__bg svg {
    height: 420px;
    width: 100%; }

.employee-card__actions {
  width: 165px; }

.employee-card__text {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 24px;
  max-width: 200px; }

.employee-card__img {
  margin-bottom: 24px;
  width: 165px;
  height: 165px;
  border-radius: 50%;
  overflow: hidden; }

.employee-card__awards {
  max-width: 200px; }
  .employee-card__awards > * {
    max-height: 50px; }

.employee-card__socials {
  margin-top: 24px; }
  .employee-card__socials a {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
    font-size: 0;
    width: 23px;
    height: 23px;
    margin-right: 10px; }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .employee-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    max-height: 220px;
    margin-bottom: 60px; }
    .employee-card__bg {
      display: none; }
    .employee-card__img {
      width: 35%;
      padding-right: calc(35% - 165px); }
    .employee-card__actions {
      margin-right: 60px; }
      .employee-card__actions-feedback {
        position: absolute;
        left: calc( 35% + 165px + 60px);
        top: 70px; }
    .employee-card__awards {
      position: absolute;
      width: auto;
      left: 35%;
      right: 0;
      bottom: 0;
      max-width: none;
      padding-right: 0; }
    .employee-card__text {
      width: 250px;
      padding-top: 7px; }
    .employee-card__socials {
      margin-top: 0; }
      .employee-card__socials a {
        margin-right: 35px;
        width: 35px;
        height: 35px; } }

@media (max-width: 767.98px) {
  .employee-card {
    width: 100%;
    position: relative;
    padding-bottom: 40px;
    text-align: center;
    margin-bottom: 36px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .employee-card__bg {
      display: none; }
    .employee-card__img {
      width: 110px;
      height: 110px;
      margin-left: auto;
      margin-right: auto; }
    .employee-card__actions {
      width: 240px;
      margin-left: auto;
      margin-right: auto; }
      .employee-card__actions-feedback {
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%); }
    .employee-card__text {
      max-width: 100%;
      font-weight: bold;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1; }
    .employee-card__socials {
      margin-top: 0;
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
      -ms-flex-order: 2;
      order: 2; }
      .employee-card__socials a {
        width: 35px;
        height: 35px; }
        .employee-card__socials a + a {
          margin-left: 35px; } }

.mp-doctors {
  margin-top: 100px;
}
  .mp-doctors__info {
    width: 435px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.93);
    padding: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 435px;
    -ms-flex: 0 0 435px;
    flex: 0 0 435px; }
  .mp-doctors__text {
    margin-bottom: 40px;
    font-size: 40px;
    font-weight: 700;
    line-height: 45px; }
  .mp-doctors__slider {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc( 100% -  435px - 110px);
    -ms-flex: 0 0 calc( 100% -  435px - 110px);
    flex: 0 0 calc( 100% -  435px - 110px);
    position: relative;
    z-index: 2;
    width: calc( 100% -  435px - 110px);
    max-width: calc( 100% -  435px - 110px);
    margin-left: 130px;
    padding-top: 30px;
    margin-bottom: 60px; }
    
    @media (min-width: 768px) {
      .mp-doctors__slider * {
        min-width: 0;
        min-height: 0; } }
    .mp-doctors__slider .btn {
      top: 0;
      position: absolute;
      z-index: 8;
      left: 470px; }
    .mp-doctors__slider-nav {
      margin: 0 0 0 33px;
      height: 40px; }
    .news__slider-nav{
      margin-left: 20px;
      height: 40px;
      width: unset;
    }
    .feedback__slider-nav {
      margin-left: 20px;
      height: 40px;
      width: unset;
      padding-bottom: 55px;
    }
    @media (min-width: 1200px) {
      .mp-doctors__slider .slick-slide {
        max-width: 900px;
        width: 900px; } }
  @media (min-width: 1200px) and (max-width: 1799.98px) {
    .mp-doctors__slider-bg {
      width: 990px;
      height: auto;
      left: -120px;
      bottom: -120px; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .mp-doctors__info {
      width: 360px;
      -webkit-flex-basis: 360px;
      -ms-flex-preferred-size: 360px;
      flex-basis: 360px; }
    .mp-doctors__text {
      font-size: 32px;
      line-height: 42px; }
    .mp-doctors__slider {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 calc( 100% -  435px - 110px + 80px);
      -ms-flex: 0 0 calc( 100% -  435px - 110px + 80px);
      flex: 0 0 calc( 100% -  435px - 110px + 80px);
      width: calc( 100% -  435px - 110px + 80px);
      margin-left: 50px; }
      .mp-doctors__slider-bg {
        width: 990px;
        height: auto;
        left: -120px;
        bottom: -120px; }
      .mp-doctors__slider .btn {
        left: auto;
        right: 0; }
      .mp-doctors__slider-nav {
          display: none; } }
  @media (max-width: 991.98px) {
    .mp-doctors {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .mp-doctors .title-1 {
        position: relative;
        z-index: 5; }
      .mp-doctors__info {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        position: relative;
        z-index: 3;
        background: none; }
      .mp-doctors__text {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 30px; }
      .mp-doctors__slider {
        width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin: 0;
        margin-bottom: 120px;
        margin-top: 30px; }
        .mp-doctors__slider .btn {
          top: auto;
          bottom: -90px;
          left: 15px; }
        .mp-doctors__slider .slick-list {
          overflow: visible !important; }
        .mp-doctors__slider-bg {
          top: -30px;
          left: calc( -65vw + 50%);
          width: 130vw;
          height: 97.5vw; }
        .mp-doctors__slider-nav {
            display: none; } }
  @media (max-width: 767.98px) {
    .mp-doctors {
      padding-top: 30px;
      margin-top: 0;
      margin-right: 0; }
      .mp-doctors .title-1 {
        margin: 0; }
      .mp-doctors__slider {
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 110px;
        min-width: 100%; }
        .mp-doctors__slider .btn {
          bottom: -60px;
          left: 0; }
        .mp-doctors__slider-items {
           }
        .mp-doctors__slider .slick-slide {
          margin: 0 15px; }
        .mp-doctors__slider .slick-dots {
          margin: 30px 15px 4px;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
          .mp-doctors__slider .slick-dots li {
            margin-bottom: 11px; }
        .mp-doctors__slider-nav {
          display: none; }
        .mp-doctors__slider-bg {
          top: -60px;
          width: 650px;
          height: 509px; }
      .mp-doctors__info {
        display: none; } }
  .mp-doctors__doc {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: start;
    margin-top: 15px; 
  }
  .mp-doctors__doc-img {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin: 10px 20px 0 10px;
      width: 115px;
      height: 115px;
      border-radius: 50%;
      overflow: hidden; }
    .mp-doctors__doc-name {
      color: #55566a;
      font-family: "TT Norms";
      font-size: 21px;
      font-weight: 700;
      line-height: 22px;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;
      margin-right: 30px; }
    .mp-doctors__doc-role {
      margin-top: 15px;
      color: #55566a;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px; }
    @media (max-width: 991.98px) {
      .mp-doctors__doc {
        margin-right: 30px;
        margin-top: 30px; } }
    @media (max-width: 767.98px) {
      .mp-doctors__doc-img {
        width: 100px;
        height: 100px; } }

@media (max-width: 991.98px) {
  .mp-centers-grid {
    font-size: 14px;
    line-height: 18px;
    margin: 25px 0 5px;
    padding: 15px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-height: 450px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }
    .mp-centers-grid::-webkit-scrollbar {
      width: 0; }
    .mp-centers-grid > * {
      width: 150px;
      height: 120px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 120px;
      -ms-flex: 0 1 120px;
      flex: 0 1 120px;
      overflow: hidden; } }

@media (max-width: 767.98px) {
  .mp-centers-grid {
    margin: 15px -15px 0;
    height: 300px; } }
  @media screen and (max-width: 767.98px) and (max-width: 530px) {
    .mp-centers-grid > * {
      width: 38vw; } }
  @media screen and (max-width: 767.98px) and (max-width: 400px) {
    .mp-centers-grid > * {
      width: 55vw; } }

.mp-first-screen-sm-revers .slick-active {
  opacity: 0.5; }
.mp-first-screen-sm-revers .slick-current {
  opacity: 1; }

@media (max-width: 767.98px) {
  .mp-first-screen-sm-revers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
    .mp-first-screen-sm-revers .bg-outline {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
      margin-top: -60px;
      padding-bottom: 60px; }
      .mp-first-screen-sm-revers .bg-outline svg {
        opacity: 0.3;
        right: calc( 50vw - (747px / 2));
        width: 747px;
        height: 680px; }
      .mp-first-screen-sm-revers .big-slider .slick-list {
        margin-left: -15px;
        margin-right: -15px; } }

.bg-outline {
  position: relative;
  height: 0;
  width: 100%;
  margin-top: -200px;
  pointer-events: none;
  padding-bottom: 200px; }
  .bg-outline svg {
    position: absolute;
    z-index: -1;
    right: -15px;
    top: 0;
    width: 82.29167vw;
    height: 74.89583vw;
    min-width: 1580px;
    min-height: 1440px;
    width: 1580px;
    height: 1440px;
    max-width: none;
    max-height: none; }
  @media (max-width: 1799.98px) {
    .bg-outline svg {
      right: -5vw;
      width: 110vw;
      min-width: auto;
      min-height: auto; } }
  @media (max-width: 1199.98px) {
    .bg-outline svg {
      opacity: 0.15;
      height: 100vw; } }

.directions-item__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #55566a;
  font-family: "TT Norms";
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  border-bottom: 2px solid #f8f8f8;
  height: 90px;
  cursor: pointer;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s; }
  .directions-item__title svg {
    width: 38px;
    height: 43px;
    fill: #44bbb7;
    margin-right: 26px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 38px;
    -ms-flex: 0 0 38px;
    flex: 0 0 38px; }
  .directions-item__title:hover {
    border-bottom-color: #F22C8B; }
  @media (max-width: 767.98px) {
    .directions-item__title {
      font-size: 16px;
      line-height: 22px; } }

.directions-item__inner {
  display: none;
  padding-left: 64px; }
  @media (max-width: 991.98px) {
    .directions-item__inner {
      padding-left: 0; } }
  .directions-item__inner-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 20px 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    position: relative; }
    .directions-item__inner-row:hover .directions-item__inner-action {
      opacity: 1; }
    @media (min-width: 768px) {
      .directions-item__inner-row .directions-item__inner-title svg {
        display: none; } }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .directions-item__inner-row {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        .directions-item__inner-row .directions-item__inner-title {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 calc( 100% - 180px);
          -ms-flex: 0 0 calc( 100% - 180px);
          flex: 0 0 calc( 100% - 180px);
          margin: 0; }
        .directions-item__inner-row .directions-item__inner-address {
          -webkit-box-ordinal-group: 4;
          -webkit-order: 3;
          -ms-flex-order: 3;
          order: 3;
          margin-top: -20px;
          margin-left: 0;
          max-width: 260px; }
        .directions-item__inner-row .directions-item__inner-phone {
          -webkit-box-ordinal-group: 5;
          -webkit-order: 4;
          -ms-flex-order: 4;
          order: 4;
          text-align: right;
          margin-right: 180px;
          margin-top: -20px;
          max-width: 160px; }
        .directions-item__inner-row .directions-item__inner-action {
          opacity: 1;
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 150px;
          -ms-flex: 0 0 150px;
          flex: 0 0 150px;
          -webkit-box-ordinal-group: 3;
          -webkit-order: 2;
          -ms-flex-order: 2;
          order: 2; } }
    @media (max-width: 767.98px) {
      .directions-item__inner-row {
        padding: 15px 0; }
        .directions-item__inner-row:not(:last-child) {
          border-bottom: 2px solid #f8f8f8; }
        .directions-item__inner-row .directions-item__inner-title {
          font-size: 16px;
          line-height: 22px;
          white-space: normal;
          position: relative;
          padding-right: 15px; }
          .directions-item__inner-row .directions-item__inner-title svg {
            position: absolute;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(calc( -50% + 2px)) rotate(-90deg) translateY(15px);
            -ms-transform: translateY(calc( -50% + 2px)) rotate(-90deg) translateY(15px);
            transform: translateY(calc( -50% + 2px)) rotate(-90deg) translateY(15px);
            width: 15px;
            height: 8px;
            -webkit-transform-origin: 0 100%;
            -ms-transform-origin: 0 100%;
            transform-origin: 0 100%;
            fill: #eee; }
          .directions-item__inner-row .directions-item__inner-title::before, .directions-item__inner-row .directions-item__inner-title::after {
            display: none; }
        .directions-item__inner-row .directions-item__inner-address,
        .directions-item__inner-row .directions-item__inner-phone,
        .directions-item__inner-row .directions-item__inner-action {
          display: none; } }
  .directions-item__inner-title {
    color: #55566a !important;
    font-family: "TT Norms";
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 440px;
    -ms-flex: 0 0 440px;
    flex: 0 0 440px;
    max-width: 100%;
    margin-right: 30px;
    text-decoration: none !important; }
    @media (min-width: 1200px) {
      .directions-item__inner-title::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 440px;
        content: ""; } }
    .directions-item__inner-title::before {
      position: absolute;
      content: "";
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      border-bottom: 2px solid #f8f8f8; }
    .directions-item__inner-title:hover::before {
      border-color: #F22C8B; }
  .directions-item__inner-address {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin: 0 5px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis; }
    .directions-item__inner-address a {
      color: #55566a; }
    .directions-item__inner-address svg {
      width: 12px;
      height: 16px;
      margin-right: 13px;
      fill: #55566a; }
  .directions-item__inner-phone {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin: 0 15px; }
    .directions-item__inner-phone a {
      color: #55566a; }
    .directions-item__inner-phone svg {
      width: 14px;
      height: 14px;
      margin-right: 13px;
      fill: #55566a; }
  .directions-item__inner-action {
    margin-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    opacity: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s; }

.sub-direction {
  width: 20%;
  padding: 0 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .sub-direction__img {
    width: 100%;
    height: 204px;
    border-radius: 20px;
    margin-bottom: 15px;
    overflow: hidden; }
  .sub-direction__title {
    color: #55566a;
    font-family: Futura;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 45px;
    min-height: 100px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
  .sub-direction__text {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding: 10px 0;
    -webkit-box-flex: 1111;
    -webkit-flex: 1111 0 auto;
    -ms-flex: 1111 0 auto;
    flex: 1111 0 auto; }
    .sub-direction__text p + p {
      display: block;
      padding: 10px 0;
      margin: 0;
      border-top: 2px solid #f8f8f8; }
  .sub-direction__actions {
    text-align: center; }
    .sub-direction__actions-service {
      margin: 10px 0; }
    .sub-direction__actions-info a {
      color: #55566a;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-decoration: underline; }
  @media (max-width: 1199.98px) {
    .sub-direction {
      min-height: 180px;
      margin-bottom: 30px;
      width: 100%;
      position: relative;
      padding-left: 225px;
      padding-right: 245px; }
      .sub-direction__img {
        position: absolute;
        left: 15px;
        top: 0;
        width: 180px;
        height: 180px; }
      .sub-direction__title {
        min-height: auto;
        margin-bottom: 15px; }
      .sub-direction__text {
        padding-top: 0; }
      .sub-direction__actions {
        position: absolute;
        right: 15px;
        top: 0;
        width: 200px; } }
  @media (max-width: 991.98px) {
    .sub-direction {
      padding-left: 150px;
      padding-right: 230px; }
      .sub-direction__img {
        border-radius: 10px;
        width: 120px;
        height: 120px; } }
  @media (max-width: 767.98px) {
    .sub-direction {
      padding-left: 15px;
      padding-right: 15px;
      min-height: auto; }
      .sub-direction + .sub-direction {
        border-top: 2px solid #f8f8f8;
        padding-top: 15px; }
        .sub-direction + .sub-direction .sub-direction__img {
          top: 15px;
          margin-bottom: 0; }
      .sub-direction__title {
        min-height: 68px;
        padding-left: 83px;
        font-size: 20px;
        line-height: 22px; }
      .sub-direction__img {
        border-radius: 10px;
        width: 68px;
        height: 68px; }
      .sub-direction__actions {
        position: static;
        width: 100%;
        text-align: left; }
        .sub-direction__actions-service {
          margin-top: 0; } }

.direction2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 30px;
  background-color: #fff;
  margin-top: 60px;
  -webkit-box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05);
  box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05);
  border-radius: 20px; }
  .direction2__img {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 109px;
    height: 109px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 25px;
    margin-top: -53px; }
  .direction2__title {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-bottom: 5px;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }
  .direction2__service {
    position: relative;
    padding: 15px 0;
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    color: #999999;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    max-height: 100px;
    overflow: hidden;
    -webkit-transition: .15s;
    -o-transition: .15s;
    transition: .15s;
    padding-right: 20px; }
    .direction2__service + .direction2__service {
      border-top: 1px solid #f8f8f8; }
    .direction2__service svg {
      height: 14px;
      width: 14px;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      position: absolute;
      right: 0;
      top: calc( 50% - 7px);
      fill: #999999;
      -webkit-transition: .15s;
      -o-transition: .15s;
      transition: .15s; }
    .direction2__service:hover {
      color: #0ABAB5;
      text-decoration: none; }
      .direction2__service:hover svg {
        fill: #0ABAB5;
        -webkit-transform: rotate(-90deg) scale(1.2);
        -ms-transform: rotate(-90deg) scale(1.2);
        transform: rotate(-90deg) scale(1.2); }
  .direction2__actions {
    margin-top: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    text-align: center; }
    .direction2__actions ._link {
      color: #55566a;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-decoration: underline;
      display: inline-block;
      margin-top: 15px; }
      .direction2__actions ._link:hover {
        text-decoration: none; }

/*.benefit {*/
/*  text-align: center;*/
/*  margin: 0 15px;*/
/*  max-width: 180px; }*/
/*  .benefit__icon {*/
/*    margin: 0 auto;*/
/*    width: 126px;*/
/*    height: 126px;*/
/*    background-color: #eefdfa;*/
/*    border-radius: 50%;*/
/*    display: -webkit-box;*/
/*    display: -webkit-flex;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    -webkit-box-align: center;*/
/*    -webkit-align-block-services: center;*/
/*    -ms-flex-align: center;*/
/*    align-block-services: center;*/
/*    -webkit-box-pack: center;*/
/*    -webkit-justify-content: center;*/
/*    -ms-flex-pack: center;*/
/*    justify-content: center; }*/
/*    .benefit__icon svg {*/
/*      width: 62px;*/
/*      height: 70px; }*/
/*  .benefit__title {*/
/*    color: #55566a;*/
/*    font-family: Futura;*/
/*    font-size: 22px;*/
/*    font-weight: 700;*/
/*    line-height: 24px;*/
/*    margin-top: 6px;*/
/*    margin: 30px 0; }*/
/*  @media (max-width: 767.98px) {*/
/*    .benefit {*/
/*      max-width: calc( 50% - 30px); }*/
/*      .benefit__icon {*/
/*        width: 100px;*/
/*        height: 100px; }*/
/*      .benefit__title {*/
/*        font-size: 20px;*/
/*        line-height: 22px;*/
/*        margin-top: 5px; } }*/

.center-bg {
  position: relative;
  z-index: -1;
  height: 20px; }
  .center-bg .center-bg-img {
    position: absolute;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
    .center-bg .center-bg-img::before {
      position: absolute;
      z-index: 1;
      content: "";
      height: 360px;
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#ffffff));
      background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%); }
  .center-bg img {
    width: 100vw; }

.blockquote {
  margin: 150px 0 90px;
  padding: 100px 50px 40px;
  text-align: center;
  border-radius: 10px;
  border: 10px solid #F22C8B;
  background-color: rgba(196, 196, 196, 0);
  position: relative; }
  .blockquote__img {
    width: 160px;
    height: 160px;
    border-radius: 130px;
    overflow: hidden;
    border: 15px solid #ffffff;
    background-color: rgba(255, 255, 255, 0);
    position: absolute;
    top: -5px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff; }
    .blockquote__img img {
      width: 100%;
      height: 100%;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
      -o-object-fit: cover;
      object-fit: cover; }
  .blockquote p {
    color: #55566a;
    font-family: Futura;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px; }
  @media (max-width: 991.98px) {
    .blockquote {
      margin: 90px 0 60px; } }
  @media (max-width: 767.98px) {
    .blockquote {
      margin: 75px 0 45px;
      padding: 80px 15px 30px; } }

.blockquote-simple {
  margin: 60px 0;
  padding: 120px 90px 60px;
  text-align: center;
  position: relative;
  z-index: 1; }
  .blockquote-simple__in {
    position: absolute;
    left: 48px;
    top: 18px;
    width: 58px;
    height: 46px; }
  .blockquote-simple__out {
    position: absolute;
    right: 48px;
    bottom: 18px;
    width: 58px;
    height: 46px; }
  .blockquote-simple__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
    .blockquote-simple__bg svg {
      width: 100%;
      height: 100%; }
  .blockquote-simple__img {
    width: 160px;
    height: 160px;
    border-radius: 130px;
    overflow: hidden;
    border: 15px solid #ffffff;
    background-color: rgba(255, 255, 255, 0);
    position: absolute;
    top: 25px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff; }
    .blockquote-simple__img img {
      width: 100%;
      height: 100%;
      -o-object-position: 50% 50%;
      object-position: 50% 50%; }
  .blockquote-simple__name {
    color: #55566a;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 10px; }
  .blockquote-simple__role {
    color: #b2b2b2;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px; }
  .blockquote-simple__text {
    font-style: italic;
    font-weight: bold;
    color: #55566a;
    font-size: 16px;
    line-height: 22px; }
  @media (max-width: 767.98px) {
    .blockquote-simple {
      margin: 60px 0 30px;
      padding: 120px 15px 60px; }
      .blockquote-simple__in {
        left: 0px;
        top: 18px; }
      .blockquote-simple__out {
        right: 0px;
        bottom: 18px; } }

.center-search {
  position: relative; }
  .center-search button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s; }
    .center-search button svg {
      width: 15px;
      height: 15px;
      fill: #0ABAB5; }
      .center-search button svg:hover {
        fill: #F22C8B; }

.service {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 20px 0;
  position: relative; }
  .service__sale {
    position: absolute;
    color: #0ABAB5;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    left: -25px;
    top: 33px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%); }
    .service__sale::before {
      content: "";
      position: absolute;
      bottom: -3px;
      height: 4px;
      border-radius: 2px;
      left: 0;
      right: 0;
      background-color: #0ABAB5; }
    @media screen and (max-width: 1300px) {
      .service__sale {
        position: relative;
        left: 0;
        top: 2px;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        margin-right: 15px; } }
  .service__title {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 770px;
    -ms-flex: 0 0 770px;
    flex: 0 0 770px;
    text-decoration: none !important; }
    .service__title::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      border-bottom: 2px solid #f8f8f8; }
    .service__title:hover {
      color: #55566a; }
      .service__title:hover::before {
        border-bottom-color: #F22C8B; }
    ._sale .service__title {
      color: #0ABAB5; }
  .service__price {
    color: #55566a;
    font-family: Futura;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
    .service__price-old {
      color: #0ABAB5;
      font-family: Futura;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-decoration: line-through; }
    .service__price-new {
      color: #0ABAB5; }
  .service__action {
    position: relative;
    z-index: 4;
    margin-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    opacity: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s; }
  .service:hover .service__action {
    opacity: 1; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .service__title {
      -webkit-flex-basis: 640px;
      -ms-flex-preferred-size: 640px;
      flex-basis: 640px; }
    .service__sale + .service__title {
      -webkit-flex-basis: 585px;
      -ms-flex-preferred-size: 585px;
      flex-basis: 585px; } }
  @media (max-width: 991.98px) {
    .service__action {
      opacity: 1; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .service__title {
      -webkit-flex-basis: 370px;
      -ms-flex-preferred-size: 370px;
      flex-basis: 370px;
      font-size: 22px;
      font-weight: 500;
      line-height: 24px; }
    .service__sale + .service__title {
      -webkit-flex-basis: 315px;
      -ms-flex-preferred-size: 315px;
      flex-basis: 315px; } }
  @media (max-width: 767.98px) {
    .service {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .service__title {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 22px; }
      .service__sale {
        top: -13px; }
      .service__price {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto; }
      .service__action {
        max-width: 180px;
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 136px;
        -ms-flex: 1 1 136px;
        flex: 1 1 136px; }
        .service__action .btn {
          width: 100%; } }

.center-media .row {
  margin: 0 -25px; }

.center-media__video, .center-media__img {
  padding: 0 25px 45px; }
  .center-media__video iframe, .center-media__video img, .center-media__video video, .center-media__img img, .center-media__img video {
    border-radius: 20px; }
  .center-media__video i, .center-media__img i {
    margin-top: 25px;
    display: block; }
  .center-media__video video, .center-media__img video {
    max-width: 100%;
    max-height: 100%; }

.video-wrap {
  position: relative;
  z-index: 2;
  line-height: 0;
  font-size: 0; }
  .video-wrap .video-cover {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer; }
    .video-wrap .video-cover img {
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s; }
    .video-wrap .video-cover svg {
      position: absolute;
      left: calc( 50% - 40px);
      top: calc( 50% - 48px);
      width: 81px;
      height: 95px;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s; }
    .video-wrap .video-cover:hover svg {
      -webkit-transform: scale(1.15);
      -ms-transform: scale(1.15);
      transform: scale(1.15); }

@media screen and (max-width: 991.98px) {
  .center-top-phones {
    padding: 27px 0 0;
    position: relative;
    background-color: #EEFDFA;
    margin: 0 15px 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: calc( 100% - 30px); }
    .center-top-phones .btn {
      margin: 15px;
      margin-top: -15px;
      white-space: nowrap; }
    .center-top-phones__scroll-wrap {
      width: 100%; }
    .center-top-phones__scroll {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: auto;
      position: relative;
      z-index: 2;
      padding: 0 45px 15px; }
      .center-top-phones__scroll a {
        white-space: nowrap;
        font-size: 18px;
        line-height: 22px;
        display: inline-block;
        padding-right: 20px; }
    .center-top-phones small {
      position: absolute;
      left: 45px;
      top: 10px; } }

@media screen and (max-width: 767.98px) {
  .center-top-phones {
    padding: 27px 0 0;
    margin: 0 0 15px;
    width: 100%; } }

@media print {
  .center-top-phones {
    min-width: 180px; } }

.articles-row > *:nth-child(1) {
  z-index: -1; }

.articles-row > *:nth-child(2) {
  z-index: -2; }

.articles-row > *:nth-child(3) {
  z-index: -3; }

.articles-row > *:nth-child(4) {
  z-index: -4; }

.articles-row > *:nth-child(5) {
  z-index: -5; }

.articles-row > *:nth-child(6) {
  z-index: -6; }

.articles-row > *:nth-child(7) {
  z-index: -7; }

.articles-row > *:nth-child(8) {
  z-index: -8; }

.articles-row > *:nth-child(9) {
  z-index: -9; }

.articles-row > *:nth-child(10) {
  z-index: -10; }

.articles-row > *:nth-child(11) {
  z-index: -11; }

.articles-row > *:nth-child(12) {
  z-index: -12; }

.articles-row > *:nth-child(13) {
  z-index: -13; }

.articles-row > *:nth-child(14) {
  z-index: -14; }

.articles-row > *:nth-child(15) {
  z-index: -15; }

.articles-row > *:nth-child(16) {
  z-index: -16; }

.articles-row > *:nth-child(17) {
  z-index: -17; }

.articles-row > *:nth-child(18) {
  z-index: -18; }

.articles-row > *:nth-child(19) {
  z-index: -19; }

.articles-row > *:nth-child(20) {
  z-index: -20; }

.articles-row > *:nth-child(21) {
  z-index: -21; }

.articles-row > *:nth-child(22) {
  z-index: -22; }

.articles-row > *:nth-child(23) {
  z-index: -23; }

.articles-row > *:nth-child(24) {
  z-index: -24; }

.articles-row > *:nth-child(25) {
  z-index: -25; }

.articles-row > *:nth-child(26) {
  z-index: -26; }

.articles-row > *:nth-child(27) {
  z-index: -27; }

.articles-row > *:nth-child(28) {
  z-index: -28; }

.articles-row > *:nth-child(29) {
  z-index: -29; }

.articles-row > *:nth-child(30) {
  z-index: -30; }

.articles-row > *:nth-child(31) {
  z-index: -31; }

.articles-row > *:nth-child(32) {
  z-index: -32; }

.articles-row > *:nth-child(33) {
  z-index: -33; }

.articles-row > *:nth-child(34) {
  z-index: -34; }

.articles-row > *:nth-child(35) {
  z-index: -35; }

.articles-row > *:nth-child(36) {
  z-index: -36; }

.articles-row > *:nth-child(37) {
  z-index: -37; }

.articles-row > *:nth-child(38) {
  z-index: -38; }

.articles-row > *:nth-child(39) {
  z-index: -39; }

.articles-row > *:nth-child(40) {
  z-index: -40; }

.articles-row > *:nth-child(41) {
  z-index: -41; }

.articles-row > *:nth-child(42) {
  z-index: -42; }

.articles-row > *:nth-child(43) {
  z-index: -43; }

.articles-row > *:nth-child(44) {
  z-index: -44; }

.articles-row > *:nth-child(45) {
  z-index: -45; }

.articles-row > *:nth-child(46) {
  z-index: -46; }

.articles-row > *:nth-child(47) {
  z-index: -47; }

.articles-row > *:nth-child(48) {
  z-index: -48; }

.articles-row > *:nth-child(49) {
  z-index: -49; }

.articles-row > *:nth-child(50) {
  z-index: -50; }

.articles-row > *:nth-child(51) {
  z-index: -51; }

.articles-row > *:nth-child(52) {
  z-index: -52; }

.articles-row > *:nth-child(53) {
  z-index: -53; }

.articles-row > *:nth-child(54) {
  z-index: -54; }

.articles-row > *:nth-child(55) {
  z-index: -55; }

.articles-row > *:nth-child(56) {
  z-index: -56; }

.articles-row > *:nth-child(57) {
  z-index: -57; }

.articles-row > *:nth-child(58) {
  z-index: -58; }

.articles-row > *:nth-child(59) {
  z-index: -59; }

.articles-row > *:nth-child(60) {
  z-index: -60; }

.articles-row > *:nth-child(61) {
  z-index: -61; }

.articles-row > *:nth-child(62) {
  z-index: -62; }

.articles-row > *:nth-child(63) {
  z-index: -63; }

.articles-row > *:nth-child(64) {
  z-index: -64; }

.articles-row > *:nth-child(65) {
  z-index: -65; }

.articles-row > *:nth-child(66) {
  z-index: -66; }

.articles-row > *:nth-child(67) {
  z-index: -67; }

.articles-row > *:nth-child(68) {
  z-index: -68; }

.articles-row > *:nth-child(69) {
  z-index: -69; }

.articles-row > *:nth-child(70) {
  z-index: -70; }

.articles-row > *:nth-child(71) {
  z-index: -71; }

.articles-row > *:nth-child(72) {
  z-index: -72; }

.articles-row > *:nth-child(73) {
  z-index: -73; }

.articles-row > *:nth-child(74) {
  z-index: -74; }

.articles-row > *:nth-child(75) {
  z-index: -75; }

.articles-row > *:nth-child(76) {
  z-index: -76; }

.articles-row > *:nth-child(77) {
  z-index: -77; }

.articles-row > *:nth-child(78) {
  z-index: -78; }

.articles-row > *:nth-child(79) {
  z-index: -79; }

.articles-row > *:nth-child(80) {
  z-index: -80; }

.articles-row > *:nth-child(81) {
  z-index: -81; }

.articles-row > *:nth-child(82) {
  z-index: -82; }

.articles-row > *:nth-child(83) {
  z-index: -83; }

.articles-row > *:nth-child(84) {
  z-index: -84; }

.articles-row > *:nth-child(85) {
  z-index: -85; }

.articles-row > *:nth-child(86) {
  z-index: -86; }

.articles-row > *:nth-child(87) {
  z-index: -87; }

.articles-row > *:nth-child(88) {
  z-index: -88; }

.articles-row > *:nth-child(89) {
  z-index: -89; }

.articles-row > *:nth-child(90) {
  z-index: -90; }

.articles-row > *:nth-child(91) {
  z-index: -91; }

.articles-row > *:nth-child(92) {
  z-index: -92; }

.articles-row > *:nth-child(93) {
  z-index: -93; }

.articles-row > *:nth-child(94) {
  z-index: -94; }

.articles-row > *:nth-child(95) {
  z-index: -95; }

.articles-row > *:nth-child(96) {
  z-index: -96; }

.articles-row > *:nth-child(97) {
  z-index: -97; }

.articles-row > *:nth-child(98) {
  z-index: -98; }

.articles-row > *:nth-child(99) {
  z-index: -99; }

.articles-row > *:nth-child(100) {
  z-index: -100; }

@media (max-width: 1199.98px) {
  .center-articles .article {
    width: 100%; }
    .center-articles .article::after {
      left: 15px; }
    .center-articles .article__img {
      left: 0px;
      top: 15px;
      width: 120px;
      height: 120px; }
    .center-articles .article__info {
      margin-right: 30px;
      padding-left: 115px;
      width: 100%;
      padding-right: 30px; } }

@media (max-width: 767.98px) {
  .center-articles .article {
    margin: 0 -60px;
    height: auto;
    max-width: none;
    width: calc( 100% + 75px); }
    .center-articles .article__img {
      position: static;
      margin-left: 60px;
      width: 90px;
      height: 90px; }
    .center-articles .article__info {
      padding-left: 60px; } }

@media (max-width: 767.98px) {
  .center-contacts .map,
  .center-contacts .map__mobile {
    display: block;
    height: auto; }
  .center-contacts .map__info {
    display: none; }
  .center-contacts .map__map {
    height: 550px;
    margin: 15px 0; }
    .center-contacts .map__map svg {
      display: none; }
  .center-contacts .map {
    margin: 0px 0px;
  }
}

.document {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 20px 0;
  position: relative; }
  .document__icon {
    width: 31px;
    height: 43px;
    position: absolute;
    left: -70px;
    top: 25px;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s; }
    @media screen and (max-width: 1300px) {
      .document__icon {
        position: static;
        margin-right: 30px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto; } }
    @media (max-width: 767.98px) {
      .document__icon {
        margin-right: 15px; } }
  .document__title {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 770px;
    -ms-flex: 0 0 770px;
    flex: 0 0 770px;
    text-decoration: none !important; }
  .documents::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    border-bottom: 2px solid #f8f8f8; }
  .documents:hover {
    color: #55566a; }
    .documents:hover .document__size {
      color: #F22C8B; }
    .documents:hover .document__icon {
      fill: #F22C8B; }
    .documents:hover::before {
      border-bottom-color: #F22C8B; }
  .document__size {
    color: #55566a;
    font-family: Futura;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  .document__action {
    position: relative;
    z-index: 4;
    margin-left: 36px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 150px;
    -ms-flex: 0 0 150px;
    flex: 0 0 150px;
    opacity: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    width: 150px; }
  .documents:hover .document__action {
    opacity: 1; }
  @media (min-width: 1200px) and (max-width: 1799.98px) {
    .document__title {
      -webkit-flex-basis: 700px;
      -ms-flex-preferred-size: 700px;
      flex-basis: 700px; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .document__title {
      -webkit-flex-basis: 560px;
      -ms-flex-preferred-size: 560px;
      flex-basis: 560px; } }
  @media (max-width: 991.98px) {
    .document__action {
      opacity: 1; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .document__title {
      -webkit-flex-basis: 370px;
      -ms-flex-preferred-size: 370px;
      flex-basis: 370px;
      font-size: 22px;
      font-weight: 500;
      line-height: 24px; } }
  @media (max-width: 767.98px) {
    .document {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .document__title {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 22px; }
      .document__size {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto; }
      .document__action {
        max-width: 180px;
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 136px;
        -ms-flex: 1 1 136px;
        flex: 1 1 136px; }
        .document__action .btn {
          width: 100%; } }

.apointment-step {
  border-radius: 50px;
  position: relative;
  /*padding: 66px 110px 110px;*/
  padding: 30px 66px 110px 66px;
  opacity: 0;
  -webkit-transition: opacity .25s;
  -o-transition: opacity .25s;
  transition: opacity .55s;
  margin-bottom: -110px;
  margin-left: -60px;
  margin-right: -60px;
  overflow: visible !important; }
  @media (max-width: 991.98px) {
    .apointment-step {
      padding-left: 95px;
      padding-right: 95px; } }

  .mg-l_step-text {
    margin-left: -8px;
  }
  .apointment-step::before {
    content: '';
    border-radius: 30px;
    z-index: -1;
    position: absolute;
    top: 44px;
    bottom: 110px;
    left: 60px;
    right: 60px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 2px 4px #00000026;;
    box-shadow: 0 2px 4px #00000026;;
    opacity: 0;
    margin-top: 17px; 
  }
  .bsh_n::before{
    box-shadow: none;
  }
  .apointment-step.show {
    opacity: 1;
    -webkit-transition: opacity .25s .2s;
    -o-transition: opacity .25s .2s;
    transition: opacity .25s .2s; }
    .apointment-step.show::before {
    opacity: 1;
  }
  .apointment-step:nth-child(1) {
    z-index: 9; 
  }
  .apointment-step:nth-child(2) {
    z-index: 8; }
  .apointment-step:nth-child(3) {
    z-index: 7; }
  .apointment-step:nth-child(4) {
    z-index: 6; }
  .apointment-step:nth-child(5) {
    z-index: 5; }
  .apointment-step:nth-child(6) {
    z-index: 4; }
  .apointment-step:nth-child(7) {
    z-index: 3; }
  .apointment-step:nth-child(8) {
    z-index: 2; }
  .apointment-step:nth-child(9) {
    z-index: 1; }
  .apointment-step:nth-child(10) {
    z-index: 0; }
  .apointment-step__inner::after {
    display: block;
    height: 22px;
    content: ''; }
  /*.apointment-step__inner ._for-doctor,*/
  /*.apointment-step__inner ._for-service {*/
  /*  border: 1px solid transparent;*/
  /*  margin: -1px; }*/
  /*.apointment-step__inner ._for-service {*/
  /*  display: none; }*/
  .apointment-step.disabled {
    color: #b2b2b2; 
  }
  .apointment-step.disabled::before {
    box-shadow: none;
  }

.apointment-success {
  display: none;
  text-align: center;
  padding-top: 45px; }
  .apointment-success svg {
    width: 126px;
    height: 126px; }

/* Альтернативно создан для неудачной записи на прием... может, фон розовым сделать */
.apointment-fail {
  display: none;
  text-align: center;
  padding-top: 45px; }
  .apointment-fail svg {
    width: 126px;
    height: 126px; }

@media (max-width: 767.98px) {
.record_type {
  margin-right: -35px;
  margin-top: 25px;
}
.pad {
  padding-left: 0;
  padding-right: 20px;
}
.mg-l_step-text {
  margin-left: -35px;
}
}
.record_type {
  justify-content: space-around;
}

.type-insurance {
  display: block;
  position: relative;
  width: 360px;
  min-height: 154px;
  border-radius: 15px;
  border: 2px solid #f8f8f8;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 22px;
  padding-left: 125px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .type-insurance__img {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 10px;
    border-radius: 10px;
    overflow: hidden; }
  .type-insurance__title {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px; }
    .type-insurance__title:hover {
      cursor: pointer; }
      .type-insurance__title:hover::before {
        opacity: 1; }
    .type-insurance__title:before {
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      display: block;
      border-radius: 10px;
      -webkit-box-shadow: 0 25px 30px rgba(0, 0, 0, 0.05);
      box-shadow: 0 25px 30px rgba(0, 0, 0, 0.05); }
  .type-insurance__chose {
    pointer-events: none;
    margin-top: 5px; }
    .type-insurance__chose .btn-outline-success {
      display: none; }
  .type-insurance input:checked ~ .type-insurance__title {
    color: #219653; }
    .type-insurance input:checked ~ .type-insurance__title:hover {
      cursor: default; }
      .type-insurance input:checked ~ .type-insurance__title:hover::before {
        opacity: 0; }
  .type-insurance input:checked ~ .type-insurance__chose .btn-outline-success {
    display: inline-block; }
  .type-insurance input:checked ~ .type-insurance__chose .btn.btn-outline-secondary {
    display: none; }
  @media (max-width: 991.98px) {
    .type-insurance {
      width: 290px;
      min-height: 120px;
      padding: 9px 9px 9px 95px; }
      .type-insurance__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px; } }
  @media (max-width: 767.98px) {
    .type-insurance {
      width: 100%; }
      .type-insurance__title {
        font-family: "TT Norms";
        font-size: 14px;
        font-weight: 700;
        line-height: 18px; } }

.OMS-link {
  display: inline-block;
  vertical-align: middle;
  width: 211px;
  height: 206px;
  -webkit-box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05);
  box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  border: 3px solid #0ABAB5;
  background-color: #ffffff;
  padding: 120px 22px 22px;
  color: #55566a;
  font-family: "TT Norms";
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: underline;
  text-align: center;
  position: relative; }
  .OMS-link img {
    position: absolute;
    top: 70px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  @media (max-width: 991.98px) {
    .OMS-link {
      width: 170px;
      height: 170px;
      font-size: 16px;
      line-height: 18px;
      padding-top: 100px; }
      .OMS-link img {
        top: 50px;
        max-height: 70px;
        max-width: 80%; } }

@media print {
  .header {
    position: absolute !important; }
  .header-bottom,
  .header-search,
  .header-order,
  .page-actions,
  .header-search-btn,
  .header-menu-btn,
  .header-phone__link span,
  .big-slider,
  .subscribe,
  .footer,
  .slick-slider-nav,
  .service__action {
    display: none !important; }
  .header-phone {
    display: block !important; }
  .container {
    width: 100%;
    max-width: none !important;
    min-width: auto !important; }
  p a[href]:after {
    content: " (" attr(href) ")"; }
  p a[href^="http"]:not([href*="mywebsite.com"]):after {
    content: " (" attr(href) ")"; }
  .service__title {
    -webkit-flex-basis: 800px;
    -ms-flex-preferred-size: 800px;
    flex-basis: 800px; }
  .print-none {
    display: none !important; }
  .print-nowrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
    .print-nowrap > * {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto; }
  .qrcode {
    display: block !important;
    width: 200px;
    padding-right: 40px;
    margin: 30px 0 30px 30px;
    float: right;
    clear: both; } }

.qrcode {
  display: none; }

.contact-card {
  font-family: "TT Norms";
  margin-top: 40px;
  -webkit-box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05);
  box-shadow: 0 50px 60px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background-color: #ffffff;
  padding: 22px 30px; }
  .contact-card-wrap:nth-child(1) {
    z-index: 99; }
  .contact-card-wrap:nth-child(2) {
    z-index: 98; }
  .contact-card-wrap:nth-child(3) {
    z-index: 97; }
  .contact-card-wrap:nth-child(4) {
    z-index: 96; }
  .contact-card-wrap:nth-child(5) {
    z-index: 95; }
  .contact-card-wrap:nth-child(6) {
    z-index: 94; }
  .contact-card-wrap:nth-child(7) {
    z-index: 93; }
  .contact-card-wrap:nth-child(8) {
    z-index: 92; }
  .contact-card-wrap:nth-child(9) {
    z-index: 91; }
  .contact-card-wrap:nth-child(10) {
    z-index: 90; }
  .contact-card-wrap:nth-child(11) {
    z-index: 89; }
  .contact-card-wrap:nth-child(12) {
    z-index: 88; }
  .contact-card-wrap:nth-child(13) {
    z-index: 87; }
  .contact-card-wrap:nth-child(14) {
    z-index: 86; }
  .contact-card-wrap:nth-child(15) {
    z-index: 85; }
  .contact-card-wrap:nth-child(16) {
    z-index: 84; }
  .contact-card-wrap:nth-child(17) {
    z-index: 83; }
  .contact-card-wrap:nth-child(18) {
    z-index: 82; }
  .contact-card-wrap:nth-child(19) {
    z-index: 81; }
  .contact-card-wrap:nth-child(20) {
    z-index: 80; }
  .contact-card-wrap:nth-child(21) {
    z-index: 79; }
  .contact-card-wrap:nth-child(22) {
    z-index: 78; }
  .contact-card-wrap:nth-child(23) {
    z-index: 77; }
  .contact-card-wrap:nth-child(24) {
    z-index: 76; }
  .contact-card-wrap:nth-child(25) {
    z-index: 75; }
  .contact-card-wrap:nth-child(26) {
    z-index: 74; }
  .contact-card-wrap:nth-child(27) {
    z-index: 73; }
  .contact-card-wrap:nth-child(28) {
    z-index: 72; }
  .contact-card-wrap:nth-child(29) {
    z-index: 71; }
  .contact-card-wrap:nth-child(30) {
    z-index: 70; }
  .contact-card-wrap:nth-child(31) {
    z-index: 69; }
  .contact-card-wrap:nth-child(32) {
    z-index: 68; }
  .contact-card-wrap:nth-child(33) {
    z-index: 67; }
  .contact-card-wrap:nth-child(34) {
    z-index: 66; }
  .contact-card-wrap:nth-child(35) {
    z-index: 65; }
  .contact-card-wrap:nth-child(36) {
    z-index: 64; }
  .contact-card-wrap:nth-child(37) {
    z-index: 63; }
  .contact-card-wrap:nth-child(38) {
    z-index: 62; }
  .contact-card-wrap:nth-child(39) {
    z-index: 61; }
  .contact-card-wrap:nth-child(40) {
    z-index: 60; }
  .contact-card-wrap:nth-child(41) {
    z-index: 59; }
  .contact-card-wrap:nth-child(42) {
    z-index: 58; }
  .contact-card-wrap:nth-child(43) {
    z-index: 57; }
  .contact-card-wrap:nth-child(44) {
    z-index: 56; }
  .contact-card-wrap:nth-child(45) {
    z-index: 55; }
  .contact-card-wrap:nth-child(46) {
    z-index: 54; }
  .contact-card-wrap:nth-child(47) {
    z-index: 53; }
  .contact-card-wrap:nth-child(48) {
    z-index: 52; }
  .contact-card-wrap:nth-child(49) {
    z-index: 51; }
  .contact-card-wrap:nth-child(50) {
    z-index: 50; }
  .contact-card-wrap:nth-child(51) {
    z-index: 49; }
  .contact-card-wrap:nth-child(52) {
    z-index: 48; }
  .contact-card-wrap:nth-child(53) {
    z-index: 47; }
  .contact-card-wrap:nth-child(54) {
    z-index: 46; }
  .contact-card-wrap:nth-child(55) {
    z-index: 45; }
  .contact-card-wrap:nth-child(56) {
    z-index: 44; }
  .contact-card-wrap:nth-child(57) {
    z-index: 43; }
  .contact-card-wrap:nth-child(58) {
    z-index: 42; }
  .contact-card-wrap:nth-child(59) {
    z-index: 41; }
  .contact-card-wrap:nth-child(60) {
    z-index: 40; }
  .contact-card-wrap:nth-child(61) {
    z-index: 39; }
  .contact-card-wrap:nth-child(62) {
    z-index: 38; }
  .contact-card-wrap:nth-child(63) {
    z-index: 37; }
  .contact-card-wrap:nth-child(64) {
    z-index: 36; }
  .contact-card-wrap:nth-child(65) {
    z-index: 35; }
  .contact-card-wrap:nth-child(66) {
    z-index: 34; }
  .contact-card-wrap:nth-child(67) {
    z-index: 33; }
  .contact-card-wrap:nth-child(68) {
    z-index: 32; }
  .contact-card-wrap:nth-child(69) {
    z-index: 31; }
  .contact-card-wrap:nth-child(70) {
    z-index: 30; }
  .contact-card-wrap:nth-child(71) {
    z-index: 29; }
  .contact-card-wrap:nth-child(72) {
    z-index: 28; }
  .contact-card-wrap:nth-child(73) {
    z-index: 27; }
  .contact-card-wrap:nth-child(74) {
    z-index: 26; }
  .contact-card-wrap:nth-child(75) {
    z-index: 25; }
  .contact-card-wrap:nth-child(76) {
    z-index: 24; }
  .contact-card-wrap:nth-child(77) {
    z-index: 23; }
  .contact-card-wrap:nth-child(78) {
    z-index: 22; }
  .contact-card-wrap:nth-child(79) {
    z-index: 21; }
  .contact-card-wrap:nth-child(80) {
    z-index: 20; }
  .contact-card-wrap:nth-child(81) {
    z-index: 19; }
  .contact-card-wrap:nth-child(82) {
    z-index: 18; }
  .contact-card-wrap:nth-child(83) {
    z-index: 17; }
  .contact-card-wrap:nth-child(84) {
    z-index: 16; }
  .contact-card-wrap:nth-child(85) {
    z-index: 15; }
  .contact-card-wrap:nth-child(86) {
    z-index: 14; }
  .contact-card-wrap:nth-child(87) {
    z-index: 13; }
  .contact-card-wrap:nth-child(88) {
    z-index: 12; }
  .contact-card-wrap:nth-child(89) {
    z-index: 11; }
  .contact-card-wrap:nth-child(90) {
    z-index: 10; }
  .contact-card-wrap:nth-child(91) {
    z-index: 9; }
  .contact-card-wrap:nth-child(92) {
    z-index: 8; }
  .contact-card-wrap:nth-child(93) {
    z-index: 7; }
  .contact-card-wrap:nth-child(94) {
    z-index: 6; }
  .contact-card-wrap:nth-child(95) {
    z-index: 5; }
  .contact-card-wrap:nth-child(96) {
    z-index: 4; }
  .contact-card-wrap:nth-child(97) {
    z-index: 3; }
  .contact-card-wrap:nth-child(98) {
    z-index: 2; }
  .contact-card-wrap:nth-child(99) {
    z-index: 1; }
  .contact-card-wrap:nth-child(100) {
    z-index: 0; }
  .contact-card__address {
    color: #55566a;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px; }
  .contact-card__timetable {
    color: #b2b2b2;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 5px; }
  .contact-card__phone {
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    margin: 15px 0; }
    .contact-card__phone a {
      color: #55566a; }
    .contact-card__phone small {
      display: block;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px; }
  .contact-card__bus {
    position: relative;
    padding-left: 37px;
    margin-top: 10px; }
    .contact-card__bus svg {
      position: absolute;
      left: 0;
      top: 4px;
      width: 23px;
      height: 23px; }
  .contact-card__map {
    position: relative;
    border-radius: 0 0 20px 20px;
    margin: 22px -30px -22px;
    display: block;
    width: calc(100% + 60px);
    height: 137px; }
    .contact-card__map img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%; }
    .contact-card__map span {
      position: absolute;
      bottom: 13px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      color: #55566a;
      font-family: "TT Norms";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-decoration: underline;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      white-space: nowrap; }
    .contact-card__map:hover span {
      color: #F22C8B; }

@media (min-width: 1200px) {
  .contacts-main__bordered {
    padding-left: 45px; }
    .contacts-main__bordered::before {
      content: '';
      position: absolute;
      z-index: 2;
      left: 15px;
      top: 0;
      bottom: 0;
      border-left: 2px solid #f8f8f8; } }

.contacts-main__map {
  overflow: hidden;
  display: block;
  height: 90px;
  border-radius: 20px;
  margin: 15px 0; }
  .contacts-main__map img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%; }

.contacts-main__contact {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  margin: 15px 0;
  color: #55566a; }

.contacts-main small {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px; }

.clinic-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 20px 0;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
  position: relative; }
  .clinic-item:hover .clinic-item__address,
  .clinic-item:hover .clinic-item__action {
    opacity: 1; }
  .clinic-item__title {
    color: #55566a !important;
    font-family: "TT Norms";
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 440px;
    -ms-flex: 0 0 440px;
    flex: 0 0 440px;
    max-width: 100%;
    margin-right: 30px;
    text-decoration: none !important; }
    @media (min-width: 1200px) {
      .clinic-item__title::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 440px;
        content: ""; } }
    .clinic-item__title::before {
      position: absolute;
      content: "";
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
      border-bottom: 2px solid #f8f8f8; }
    .clinic-item__title:hover::before {
      border-color: #F22C8B; }
  .clinic-item__address {
    color: #55566a;
    font-family: "TT Norms";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 280px;
    -ms-flex: 0 1 280px;
    flex: 0 1 280px;
    margin: 0 5px;
    opacity: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s; }
    .clinic-item__address a {
      color: #55566a; }
    .clinic-item__address svg {
      width: 12px;
      height: 16px;
      margin-right: 13px;
      fill: #55566a; }
  .clinic-item__action {
    margin-left: 15px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    opacity: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    text-align: right; }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .clinic-item {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .clinic-item__title {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 calc( 100% - 180px);
        -ms-flex: 0 0 calc( 100% - 180px);
        flex: 0 0 calc( 100% - 180px);
        margin: 0; }
      .clinic-item__address {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
        margin-top: -20px;
        margin-left: 0;
        max-width: 260px; }
      .clinic-item__action {
        opacity: 1;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 150px;
        -ms-flex: 0 0 150px;
        flex: 0 0 150px;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2; } }
  @media (max-width: 767.98px) {
    .clinic-item {
      padding: 15px 0; }
      .clinic-item:not(:last-child) {
        border-bottom: 2px solid #f8f8f8; }
      .clinic-item__title {
        font-size: 16px;
        line-height: 22px;
        white-space: normal;
        position: relative;
        padding-right: 15px; }
        .clinic-item__title svg {
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translateY(calc( -50% + 2px)) rotate(-90deg) translateY(15px);
          -ms-transform: translateY(calc( -50% + 2px)) rotate(-90deg) translateY(15px);
          transform: translateY(calc( -50% + 2px)) rotate(-90deg) translateY(15px);
          width: 15px;
          height: 8px;
          -webkit-transform-origin: 0 100%;
          -ms-transform-origin: 0 100%;
          transform-origin: 0 100%;
          fill: #eee; }
        .clinic-item__title::before, .clinic-item__title::after {
          display: none; }
      .clinic-item__address, .clinic-item__action {
        display: none; } }

.clinic-special-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
  position: relative;
  text-decoration: none !important; }
  .clinic-special-item__title {
    color: #55566a !important;
    font-family: "TT Norms";
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    margin-right: 30px; }
  .clinic-special-item::before {
    position: absolute;
    content: "";
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    border-bottom: 2px solid #f8f8f8; }
  .clinic-special-item:hover::before {
    border-color: #F22C8B; }
  .clinic-special-item:hover .clinic-special-item__action {
    opacity: 1; }
  .clinic-special-item__action {
    margin-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    opacity: 0;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    text-align: right; }

  /* Добавлено */
img.block-img {
  /*display: block;*/
  display: inline-block;
  /*border: 1px solid #ccc;*/
}
.demo-color {
  color: #F22C8B;
}
/* фиксация блока */
.content-overflow-hidden {
  overflow: -moz-hidden-unscrollable !important;
}

.link-subscribe-sticky {
  position: sticky;
  bottom: 0px;
  background: #ffffff;
  z-index: 9;
  padding: 1px 0px;
}

.baron__scroller ngb-popover-window {
  left: 260px !important;
}

.page-actions__share-inner {
  /*background: #000000;*/
}
.sb-group {
  flex-wrap: initial !important;
}
.sb-button {
  /*display: inline-block !important;*/
}
.sb-icon * {
  fill: #F22C8B !important;
  font-size: 24px;
}

@media (max-width: 991px) {
  .sb-group {
    flex-wrap: wrap !important;
  }
}

@media (max-width: 478px) {
  .jw-modal .jw-modal .jw-modal-body {
    width: 100%;
  }
  #custom-modal-3 .p-5 {
    padding: 0rem !important;
  }
  .modal-content {
    padding: 20px 20px;
  }
}

